import React from 'react';
import { Table, Button } from 'antd';
import styles from './../../scss/modules/form.module.scss';
import { withTranslation, useTranslation } from 'react-i18next';

const TableList = (
  { data, title, currentStep, onNext, subTitle, onFinish },
  formDisabled
) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      key: 'authorizationNo',
      title: t('drugs.authorizationNo'),
      dataIndex: 'authorizationNo',
      width: 150,
      fixed: 'left',
    },
    {
      key: 'dosageForm',
      title: t('drugs.dosageForm'),
      dataIndex: 'dosageForm',
      width: 120,
    },
    {
      key: 'genericName',
      title: t('drugs.genericName'),
      dataIndex: 'genericName',
      width: 120,
    },
    {
      key: 'isProduceInYear',
      title: t('drugs.isProduceInYear'),
      dataIndex: 'isProduceInYear',
      width: 130,
    },
    {
      key: 'managementProperties',
      title: t('drugs.managementProperties'),
      dataIndex: 'managementProperties',
      render: (text) => text.join(', '),
      width: 120,
    },
    {
      key: 'productName',
      title: t('drugs.productName'),
      dataIndex: 'productName',
      width: 120,
    },
    {
      key: 'productionAddress',
      title: t('drugs.productionAddress'),
      dataIndex: 'productionAddress',
      width: 120,
    },
    {
      key: 'productionBatches',
      title: t('drugs.productionBatches'),
      dataIndex: 'productionBatches',
      width: 120,
    },
    {
      key: 'productionEnterpriseName',
      title: t('drugs.productionEnterpriseName'),
      dataIndex: 'productionEnterpriseName',
      width: 120,
    },
    {
      key: 'productionLine',
      title: t('drugs.productionLine'),
      dataIndex: 'productionLine',
      width: 120,
    },
    {
      key: 'productionWorkshop',
      title: t('drugs.productionWorkshop'),
      dataIndex: 'productionWorkshop',
      width: 120,
    },
    {
      key: 'specifications',
      title: t('drugs.specifications'),
      dataIndex: 'specifications',
      width: 120,
    },
  ];

  return (
    <div className={styles.content}>
      <p className={styles.pageSubTitle}>{title}</p>
      {!!subTitle && <p className={styles.pageSubTitleSmall}>{subTitle}</p>}
      {data.length > 0 ? (
        <Table
          scroll={{ x: 1000 }}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.id}
          pagination={{
            showTotal: (total) => t('message.tableTotalCount', { total }),
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
      ) : (
        <p>持有人表单还未与药品相关联</p>
      )}
      {!formDisabled && (
        <div className={styles.footer}>
          {i18n.language === 'cn' && (
            <Button size="large" type="primary" onClick={() => onFinish()}>
              {t('button.submit')}
            </Button>
          )}
          <Button
            size="large"
            style={{ marginLeft: '20px' }}
            onClick={() => onNext(currentStep + 1)}
          >
            {t('button.next')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(TableList);
