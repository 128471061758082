import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  USER,
  TEMPLATE,
  IMPORT_FIELDS,
  BASIC_INFO_CHECKED,
} from './defaultValues';

export const getLocalAccessToken = () => {
  return localStorage.getItem('access_token');
};

export const setLocalAccessToken = (data) => {
  return localStorage.setItem('access_token', data);
};

export const getLocalBasicInforConfirmed = () => {
  const data = localStorage.getItem('basicInforChecked');
  return data ? JSON.parse(data) : BASIC_INFO_CHECKED;
};

export const setLocalBasicInforConfirmed = (data) => {
  return localStorage.setItem('basicInforChecked', data);
};

export const getLocalRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

export const setLocalRefreshToken = (data) => {
  return localStorage.setItem('refresh_token', data);
};

export const getLocalUserInfo = () => {
  const data = localStorage.getItem('user_info');
  return data ? JSON.parse(data) : USER;
};

export const setLocalUserInfo = (data) => {
  return localStorage.setItem('user_info', data);
};

export const getLocalFormTemplate = () => {
  const data = localStorage.getItem('form_template');
  return data ? JSON.parse(data) : TEMPLATE;
};

export const setLocalFormTemplate = (data) => {
  return localStorage.setItem('form_template', data);
};

export const getLocalImportFields = () => {
  const data = localStorage.getItem('import_fields');
  return data ? JSON.parse(data) : IMPORT_FIELDS;
};

export const setLocalImportFields = (data) => {
  return localStorage.setItem('import_fields', data);
};

export const getLocalYears = () => {
  const data = localStorage.getItem('years');
  return data ? JSON.parse(data) : [];
};

export const setLocalYears = (data) => {
  return localStorage.setItem('years', data);
};

export const getLocalStartNewYearDisabled = () => {
  const data = localStorage.getItem('start_new_year_disabled');
  return data ? JSON.parse(data) : true;
};

export const setLocalStartNewYearDisabled = (data) => {
  return localStorage.setItem('start_new_year_disabled', data);
};

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const formatFormValues = (fields, values) => {
  let defaultValues = {};
  fields.forEach(({ defaultValue, dataField, type }) => {
    if (values) {
      defaultValues[dataField] = changeValueType(values[dataField], type);
    } else {
      defaultValues[dataField] = changeValueType(defaultValue, type);
    }
  });
  return defaultValues;
};

export const changeValueType = (value, fieldType) => {
  if (fieldType === 'date') {
    return value ? moment(value) : undefined;
  } else if (fieldType === 'switch') {
    return value === undefined ? false : value;
  } else if (fieldType === 'multiple-select') {
    return value || [];
  } else {
    return value || undefined;
  }
};

export const formatTemplate = (list, code) => {
  const { name, enName, formId, inputAreaList } = list.find(
    (item) => item.code === code
  );
  const steps = [];
  const groups = [];
  const fields = [];

  inputAreaList.forEach((step, index) => {
    const {
      id,
      description,
      name,
      enName,
      formId,
      remark,
      tips,
      type,
      formItemList,
      formAreaName,
      holderType,
    } = step;
    steps.push({
      id,
      description,
      name,
      enName,
      formId,
      remark,
      tips,
      type,
      formAreaName,
      holderType,
    });
    formItemList.forEach((field) => {
      const { group, groupName, enGroupName, addMore, parentGroup } = field;
      if (
        groups.findIndex(
          ({ id, stepIndex }) => id === group && stepIndex === index
        ) === -1
      ) {
        groups.push({
          id: group,
          groupName,
          enGroupName,
          addMore,
          stepIndex: index,
          parentGroup,
        });
      }
      fields.push({ ...field, stepIndex: index });
    });
  });
  return {
    name,
    enName,
    formId,
    steps,
    groups,
    fields,
  };
};

export const hasFieldRole = (fieldId, permissionsIds) => {
  return permissionsIds.split(',').indexOf(fieldId.toString()) !== -1;
};

export const initListFormValues = (keys) => {
  let value = {};
  keys.forEach((key) => {
    value[key] = undefined;
  });
  return value;
};

export const addArrayItem = (list, item, index) => {
  list.splice(index + 1, 0, item);
  return list;
};

export function sortCompare(attr) {
  if (attr) {
    return (a, b) => a[attr] - b[attr];
  } else {
    return (a, b) => a - b;
  }
}

export function numberWithCommas(num) {
  return Number(num).toLocaleString();
}
