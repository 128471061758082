import { useTranslation, withTranslation } from 'react-i18next';
import { Form, Input, Select, Button, DatePicker } from 'antd';
import styles from './../../scss/modules/list.module.scss';

const ListActions = (props) => {
  const { t } = useTranslation();
  const { type, form, loading, filters, onSearch, onReset, onClickAdd } = props;

  const formItems = filters.map((item) => {
    const { name, type, options, label } = item;
    if (type === 'input') {
      return (
        <Form.Item
          key={name}
          name={name}
          label={t(`form.${label}`)}
          className={styles.item}
        >
          <Input
            placeholder={t('form.inputKeyword')}
            autoComplete={name === 'userName' ? 'username' : 'off'}
            allowClear={true}
          />
        </Form.Item>
      );
    } else if (type === 'date') {
      return (
        <Form.Item
          key={name}
          name={name}
          label={t(`form.${label}`)}
          className={styles.item}
        >
          <DatePicker placeholder={t('form.date')} allowClear={true} />
        </Form.Item>
      );
    } else if (type === 'select') {
      return (
        <Form.Item
          key={name}
          name={name}
          label={t(`form.${label}`)}
          className={styles.item}
        >
          <Select
            placeholder={t('form.select')}
            options={options}
            allowClear={true}
          />
        </Form.Item>
      );
    } else {
      return null;
    }
  });

  return (
    <>
      {filters.length > 0 && (
        <Form
          form={form}
          name="listSearch"
          className={styles.search}
          onFinish={onSearch}
          style={!type ? { marginBottom: '20px' } : {}}
        >
          <div className={styles.item_group}>{formItems}</div>
          <div className={styles.btn_group}>
            <Button type="primary" htmlType="submit">
              {t('button.search')}
            </Button>
            <Button
              style={{ marginLeft: '8px' }}
              onClick={onReset}
              loading={loading}
            >
              {t('button.reset')}
            </Button>
          </div>
        </Form>
      )}
      {type === 'account' && (
        <Button style={{ margin: '20px 0' }} onClick={onClickAdd}>
          {t(`button.${type}Add`)}
        </Button>
      )}
    </>
  );
};

export default withTranslation()(ListActions);
