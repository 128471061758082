import request from './request';

/**
 * @description auth refresh token
 * @param {object} data
 * @return {*}  {object}
 */
export const authRefreshToken = async (data) => {
  const response = request({
    url: '/api/auth/refresh_token',
    method: 'post',
    data,
  });

  return response.data.content;
};

/**
 * @description auth login
 * @param {object} data
 * @return {*}  {object}
 */
export const authLogin = async (data) => {
  const response = await request({
    url: '/api/auth/login',
    method: 'post',
    data,
  });

  return response.data.content;
};

/**
 * @description auth sso login
 * @param {object} data
 * @return {*}  {object}
 */
export const authSSOLogin = async (data) => {
  const response = await request({
    url: '/api/auth/ssologin',
    method: 'post',
    data,
  });

  return response.data.content;
};

/**
 * @description auth logout
 */
export const authLogout = async () => {
  const response = await request({
    url: '/api/auth/logout',
    method: 'post',
  });

  return response.data;
};

/**
 * @description get captcha
 */
export const getCaptcha = async () => {
  const response = await request({
    url: '/api/auth/getcaptcha',
    method: 'get',
  });

  return response.data;
};
