import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  TableOutlined,
  ProfileOutlined,
  UserOutlined,
  ContainerOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import Logo from '../../images/logo.svg';
import styles from './../../scss/modules/home.module.scss';
import { ROLE } from './../../common';

const list = [
  {
    key: 'drugs',
    path: '/drugs',
    icon: <ContainerOutlined />,
    roles: [ROLE.ADMIN, ROLE.EDITOR, ROLE.MANAGER],
  },
  {
    key: 'holder',
    path: '/holder',
    icon: <TableOutlined />,
    roles: [ROLE.ADMIN, ROLE.EDITOR, ROLE.MANAGER],
  },
  {
    key: 'files',
    path: '/files',
    icon: <FileOutlined />,
    roles: [ROLE.ADMIN, ROLE.MANAGER],
  },
  {
    key: 'logs',
    path: '/logs',
    icon: <ProfileOutlined />,
    roles: [ROLE.ADMIN, ROLE.MANAGER],
  },
  {
    key: 'users',
    path: '/users',
    icon: <UserOutlined />,
    roles: [ROLE.ADMIN],
  },
];

const SideMenu = ({ activeKey, user }) => {
  const { t } = useTranslation();
  let menuList = list
    .filter((item) => item.roles.includes(user.roleId))
    .map((item) => {
      item.label = <Link to={item.path}>{t(`menu.${item.key}`)}</Link>;
      return item;
    });

  return (
    <Layout.Sider className={styles.slider} width={208}>
      <Link className={styles.logo} to="/">
        <img className={styles.logo} alt="Gu Yu" src={Logo} />
      </Link>
      <Menu
        selectedKeys={[activeKey]}
        mode="inline"
        theme="dark"
        items={menuList}
      ></Menu>
    </Layout.Sider>
  );
};

export default withTranslation()(SideMenu);
