import React, { useEffect, useState } from 'react';
import { Form, Button, Modal, Collapse } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import camelCase from 'lodash/camelCase';
import FormItem from './FormItem';
import moment from 'moment';
import { hasFieldRole, changeValueType, sortCompare } from './../../common';
import styles from './../../scss/modules/form.module.scss';

const ChangeManagements = (props) => {
  const { t, i18n } = useTranslation();
  const {
    form,
    title,
    subTitle,
    isEdit,
    isTranslate,
    currentStep,
    groupItems,
    permissions,
    changemanagementsListValues,
    isUpload,
    fileList,
    comments,
    isAddComment,
    onAddComment,
    onShowComments,
    onFinish,
    onFileUpload,
    onFileRemove,
    onFileDownload,
    onEditFileInfo,
    onNext,
    onValuesChange,
    formDisabled,
  } = props;

  const isHaveWorkAlert = groupItems.find(
    (item) => item.dataField === 'isHaveWork_Alert'
  );
  const isHaveWorkApprovalChanges = groupItems.find(
    (item) => item.dataField === 'isHaveWork_ApprovalChanges'
  );
  const isHaveWorkFilingChanges = groupItems.find(
    (item) => item.dataField === 'isHaveWork_FilingChanges'
  );
  const isHaveWorkReportChanges = groupItems.find(
    (item) => item.dataField === 'isHaveWork_ReportChanges'
  );
  const isHaveWorkOtherReport = groupItems.find(
    (item) => item.dataField === 'isHaveWork_OtherReport'
  );

  const [collapseStatus, setCollapseStatus] = useState({
    alertList: {
      activeKey: [],
      validErrorList: [],
    },
    approvalChangesList: {
      activeKey: [],
      validErrorList: [],
    },
    filingChangesList: {
      activeKey: [],
      validErrorList: [],
    },
    reportChangesList: {
      activeKey: [],
      validErrorList: [],
    },
    otherReportList: {
      activeKey: [],
      validErrorList: [],
    },
  });

  const [fieldList, setFieldList] = useState({
    alertList: [],
    approvalChangesList: [],
    filingChangesList: [],
    reportChangesList: [],
    otherReportList: [],
  });
  const [valuesList, setValuesList] = useState({});

  useEffect(() => {
    let {
      isHaveWork_Alert,
      isHaveWork_ApprovalChanges,
      isHaveWork_FilingChanges,
      isHaveWork_ReportChanges,
      isHaveWork_OtherReport,
    } = changemanagementsListValues;

    const newFieldList = { ...fieldList };

    Object.keys(fieldList).forEach((key) => {
      const groupItem = groupItems.find((item) => item.dataField === key);
      const arrLen = changemanagementsListValues[key]?.length;
      newFieldList[key] = Array(arrLen || 1).fill(groupItem);
      if (arrLen) {
        changemanagementsListValues[key].forEach((item, index) => {
          setFieldValue(groupItem, index, item);
        });
      } else {
        changemanagementsListValues[key] = [initFieldValue(groupItem)];
      }
    });

    setFieldList(newFieldList);

    form.setFieldsValue({
      isHaveWork_Alert,
      isHaveWork_ApprovalChanges,
      isHaveWork_FilingChanges,
      isHaveWork_ReportChanges,
      isHaveWork_OtherReport,
    });

    setValuesList({
      ...changemanagementsListValues,
    });
  }, [changemanagementsListValues]);

  const getListKey = (parentGroupName) => {
    const key = camelCase(parentGroupName.split('_')[1] || '');
    return `${key}List`;
  };

  const handleValuesChange = (changedValues) => {
    const [key] = Object.keys(changedValues);
    if (
      [
        'isHaveWork_Alert',
        'isHaveWork_ApprovalChanges',
        'isHaveWork_FilingChanges',
        'isHaveWork_ReportChanges',
        'isHaveWork_OtherReport',
      ].indexOf(key) !== -1
    ) {
      const listKey = getListKey(key);
      updateCollapseErrorStatus([], listKey);

      setValuesList({
        ...valuesList,
        ...changedValues,
      });
      onValuesChange({
        ...valuesList,
        ...changedValues,
      });
    } else {
      const [value] = Object.values(changedValues);
      const [dataField, parentField, index] = key.split('-');
      const list = [...valuesList[parentField]];
      list[index][dataField] = value;
      setValuesList({
        ...valuesList,
        [parentField]: list,
      });
      onValuesChange({
        ...valuesList,
        [parentField]: list,
      });
    }
  };

  const handleAddFields = (dataField, index) => {
    const list = [...fieldList[dataField]];
    const groupItem = groupItems.find((item) => item.dataField === dataField);
    list.splice(index + 1, 0, groupItem);
    setFieldList({
      ...fieldList,
      [dataField]: list,
    });

    const newValues = [...valuesList[dataField]];
    newValues.splice(index + 1, 0, initFieldValue(groupItem));
    setValuesList({
      ...valuesList,
      [dataField]: newValues,
    });

    const status = { ...collapseStatus[dataField] };
    status.activeKey.push(index + 1);
    setCollapseStatus({
      ...collapseStatus,
      [dataField]: status,
    });
  };

  const handleDelete = (dataField, index) => {
    Modal.confirm({
      title: t('common.confirm'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteRecord'),
      okText: t('common.yes'),
      cancelText: t('common.no'),
      onOk: async () => {
        const list = [...fieldList[dataField]];
        const prevListLength = list.length;
        list.splice(index, 1);
        setFieldList({
          ...fieldList,
          [dataField]: list,
        });

        const newValues = [...valuesList[dataField]];
        newValues.splice(index, 1);
        setValuesList({
          ...valuesList,
          [dataField]: newValues,
        });

        // Reset form values
        const groupItem = groupItems.find(
          (item) => item.dataField === dataField
        );
        newValues.forEach((item, valueIndex) => {
          setFieldValue(groupItem, valueIndex, item);
        });
        // reset deleted group fileds value
        setFieldValue(groupItem, prevListLength - 1, {});

        onValuesChange({
          ...valuesList,
          [dataField]: newValues,
        });

        const status = { ...collapseStatus[dataField] };
        status.activeKey = status.activeKey
          .filter((item) => index !== +item)
          .map((item) => (+item > index ? +item - 1 : item));
        status.validErrorList = status.validErrorList.filter(
          (item) => +item !== index
        );
        setCollapseStatus({
          ...collapseStatus,
          [dataField]: status,
        });
      },
    });
  };

  const setFieldValue = (list, index, valueObj) => {
    const childrenItems = list.childrenItemList.filter(
      (item) => !item.dataField.includes('upload')
    );
    childrenItems.forEach(({ dataField, type }) => {
      const value = changeValueType(valueObj[dataField], type);
      form.setFieldValue(`${dataField}-${list.dataField}-${index}`, value);
    });
  };

  const initFieldValue = (list) => {
    const childrenItems = list.childrenItemList.filter(
      (item) => !item.dataField.includes('upload')
    );
    let initValues = {};
    childrenItems.forEach((item) => {
      const { dataField, defaultValue, type } = item;
      initValues[dataField] = changeValueType(defaultValue, type);
    });
    return initValues;
  };

  const onCollapseChange = (keyList, dataField) => {
    const status = { ...collapseStatus[dataField] };
    status.activeKey = keyList;
    setCollapseStatus({
      ...collapseStatus,
      [dataField]: status,
    });
  };

  const updateCollapseErrorStatus = (errorList, dataField) => {
    const collapse = { ...collapseStatus };
    if (!dataField) {
      Object.keys(collapseStatus).forEach((item) => {
        collapse[item].validErrorList = errorList?.[item] || [];
      });
    } else {
      const { [dataField]: collapeseItem } = collapse;
      collapeseItem.activeKey = [0];
      collapeseItem.validErrorList = errorList?.[dataField] || [];
    }

    setCollapseStatus(collapse);
  };

  const handleFinish = () => {
    // remover collapese error status.
    updateCollapseErrorStatus();
    onFinish(valuesList);
  };

  const handleFinishFailed = ({ errorFields }) => {
    const errorList = {};
    errorFields.forEach((item) => {
      const [fieldName] = item.name;
      if (
        [
          'isHaveWork_Alert',
          'isHaveWork_ApprovalChanges',
          'isHaveWork_FilingChanges',
          'isHaveWork_ReportChanges',
          'isHaveWork_OtherReport',
        ].indexOf(fieldName) === -1
      ) {
        const [, parentField, index] = fieldName.split('-');
        if (!errorList[parentField]) {
          errorList[parentField] = [index];
        } else if (errorList[parentField].indexOf(index) === -1) {
          errorList[parentField].push(index);
        }
      }
    });

    updateCollapseErrorStatus(errorList);
  };

  const renderGroupItem = (item, list) => {
    const listTemp = list?.[0];
    const parentDisabled =
      item.disabled || !hasFieldRole(item.id, permissions) || formDisabled;

    return (
      <div className={styles.group}>
        <p style={{ width: '100%' }}>
          {i18n.language === 'cn' ? item.groupName : item.enGroupName}
        </p>
        <FormItem
          className={styles.withoutMaxiWidth}
          {...item}
          readOnly={
            i18n.language === 'en'
              ? item.readOnly || !isTranslate
              : item.readOnly
          }
          disabled={parentDisabled}
          isAddComment={isAddComment}
          comments={comments.filter((comment) => comment.fieldId === item.id)}
          onAddComment={() => onAddComment(item.dataField, item.id)}
          onShowComments={() => onShowComments(item.dataField, item.id)}
        />
        {valuesList[item.dataField] === listTemp?.parentValue && (
          <>
            <Collapse
              style={{ width: '100%' }}
              className={styles.collapse}
              activeKey={collapseStatus[listTemp?.dataField]?.activeKey}
              onChange={(e) => onCollapseChange(e, listTemp?.dataField)}
            >
              {list.map((child, index) => {
                const { validErrorList } =
                  collapseStatus[child.dataField] || {};
                const hasError = validErrorList?.indexOf(index.toString()) > -1;
                return (
                  <Collapse.Panel
                    forceRender
                    key={index}
                    className={`${styles.collapseItem} ${
                      hasError && 'collapse-item-error'
                    }`}
                    header={`${t('form.item')} ${index + 1}`}
                  >
                    {hasFieldRole(child.id, permissions) &&
                      !parentDisabled &&
                      fieldList[child.dataField].length > 1 && (
                        <Button
                          className={`delete-btn ${styles.deleteIcon}`}
                          size="small"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => handleDelete(child.dataField, index)}
                        />
                      )}
                    {child.childrenItemList
                      .sort(sortCompare('sort'))
                      .map((field, childIndex) => {
                        const itemFileList =
                          field.type === 'upload'
                            ? fileList.filter(
                                (file) =>
                                  file.fieldId ===
                                    valuesList[child.dataField][index]?.id &&
                                  file.dataField === field.dataField
                              )
                            : [];
                        return (
                          <FormItem
                            {...field}
                            initFieldValue={
                              valuesList[child.dataField][index][
                                field.dataField
                              ]
                            }
                            dataField={`${field.dataField}-${child.dataField}-${index}`}
                            key={`${field.dataField}-${index}-${childIndex}`}
                            readOnly={
                              i18n.language === 'en'
                                ? field.readOnly || !isTranslate
                                : field.readOnly
                            }
                            disabled={
                              parentDisabled ||
                              field.disabled ||
                              !hasFieldRole(child.id, permissions)
                            }
                            isAddComment={
                              isAddComment &&
                              valuesList[child.dataField][index]?.id
                            }
                            isUpload={
                              isUpload && valuesList[child.dataField][index]?.id
                            }
                            comments={comments.filter(
                              (comment) =>
                                comment.fieldId ===
                                  valuesList[child.dataField][index]?.id &&
                                comment.dataField === field.dataField
                            )}
                            onAddComment={() =>
                              onAddComment(
                                field.dataField,
                                valuesList[child.dataField][index]?.id
                              )
                            }
                            onShowComments={() =>
                              onShowComments(
                                field.dataField,
                                valuesList[child.dataField][index]?.id
                              )
                            }
                            fileList={itemFileList}
                            maxCount={1}
                            isHidden={
                              field.parentField
                                ? valuesList[child.dataField][index][
                                    field.parentField
                                  ] !== field.parentValue
                                : false
                            }
                            onFileUpload={(config) =>
                              onFileUpload(config, {
                                listId: valuesList[child.dataField][index]?.id,
                                dataField: field.dataField,
                              })
                            }
                            onFileRemove={onFileRemove}
                            onFileDownload={onFileDownload}
                            onEditFileInfo={onEditFileInfo}
                          />
                        );
                      })}
                  </Collapse.Panel>
                );
              })}
            </Collapse>

            {listTemp &&
              !parentDisabled &&
              hasFieldRole(listTemp.id, permissions) && (
                <div className={styles.addMoreButtons}>
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      handleAddFields(listTemp.dataField, list.length - 1)
                    }
                  >
                    {t('form.add')}
                  </Button>
                </div>
              )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className={styles.content}>
      <p className={styles.pageSubTitle}>{title}</p>
      {!!subTitle && <p className={styles.pageSubTitleSmall}>{subTitle}</p>}
      <Form
        form={form}
        size="large"
        colon={false}
        layout="vertical"
        scrollToFirstError={true}
        onValuesChange={handleValuesChange}
        // onFinish={() => onFinish(valuesList)}
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        preserve={false}
      >
        {renderGroupItem(isHaveWorkAlert, fieldList.alertList)}
        {renderGroupItem(
          isHaveWorkApprovalChanges,
          fieldList.approvalChangesList
        )}
        {renderGroupItem(isHaveWorkFilingChanges, fieldList.filingChangesList)}
        {renderGroupItem(isHaveWorkReportChanges, fieldList.reportChangesList)}
        {renderGroupItem(isHaveWorkOtherReport, fieldList.otherReportList)}
        {!formDisabled && (
          <div className={styles.footer}>
            {i18n.language === 'cn' && (
              <Button size="large" type="primary" htmlType="submit">
                {t('button.submit')}
              </Button>
            )}
            {i18n.language === 'cn' && (
              <Button
                size="large"
                type="primary"
                onClick={() => onFinish(valuesList, false)}
              >
                {t('button.save')}
              </Button>
            )}
            {i18n.language === 'en' && isTranslate && (
              <Button size="large" type="primary" htmlType="submit">
                {t('button.translate')}
              </Button>
            )}
            <Button
              size="large"
              style={{ marginLeft: '20px' }}
              onClick={() => onNext(currentStep + 1)}
            >
              {t('button.next')}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default withTranslation()(ChangeManagements);
