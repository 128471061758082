import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Form, Input, Button, Tabs } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { authLogin, authSSOLogin, getCaptcha } from '../api';
import Logo from './../images/logo.svg';
import styles from './../scss/modules/login.module.scss';
import { useQuery, AppContext } from './../common';
import { AppFooter } from './../components';

const UserLogin = () => {
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [codeBase64Img, setCodeBase64Img] = useState('');
  const [captchaId, setCaptchaId] = useState('');
  const { Content } = Layout;
  const { Item } = Form;
  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const code = useQuery().get('code') || '';

  useEffect(() => {
    if (state.isAuth) {
      navigate('/');
      return;
    } else if (code) {
      handleSSOLogin();
    } else {
      // handleGetCaptcha();
    }
  }, []);

  /**
   *
   * @param params - loginId, password and captchaValue
   */
  const handlePasswordLogin = async (params) => {
    setLoading(true);
    try {
      // const { model } = await authLogin({ ...params, captchaId });
      const { model } = await authLogin({ ...params });
      dispatch({
        type: 'login',
        payload: model,
      });
      navigate('/');
    } catch (err) {
    } finally {
      setLoading(false);
      // handleGetCaptcha();
    }
  };

  // SSO login
  const handleSSOLogin = async () => {
    setLoading(true);
    try {
      const { model } = await authSSOLogin({ code });
      dispatch({
        type: 'login',
        payload: model,
      });
      navigate('/');
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  // Get captcha
  const handleGetCaptcha = async () => {
    try {
      const { id, data, mimeType } = await getCaptcha();
      setCaptchaId(id);
      setCodeBase64Img(`data:${mimeType};base64, ${data}`);
    } catch (err) {
      setCaptchaId('');
      setCodeBase64Img('');
    }
  };

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <div className={styles.title}>
          <img className={styles.logo} src={Logo} alt="Gu Yu" />
        </div>
        <Tabs defaultActiveKey={code ? '2' : ''}>
          <TabPane tab="Password to login" key="1">
            <Form className={styles.form} onFinish={handlePasswordLogin}>
              <Item
                name="loginId"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Username!',
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className={styles.icon} />}
                  size="large"
                  placeholder="Username"
                  autoComplete="off"
                />
              </Item>
              <Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className={styles.icon} />}
                  size="large"
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                />
              </Item>
              {/* <div className={styles.captcha}>
                <Item
                  style={{ width: '60%' }}
                  name="captchaValue"
                  rules={[
                    {
                      required: true,
                      message: 'Please input verification code!',
                    },
                  ]}
                >
                  <Input
                    prefix={<SafetyOutlined className={styles.icon} />}
                    size="large"
                    placeholder="Code"
                    autoComplete="off"
                  />
                </Item>
                <div className={styles.code} onClick={handleGetCaptcha}>
                  {codeBase64Img ? (
                    <img
                      style={{ display: 'block' }}
                      src={codeBase64Img}
                      alt="验证码"
                    />
                  ) : (
                    '点击刷新'
                  )}
                </div>
              </div> */}
              <Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={loading}
                >
                  Log in
                </Button>
              </Item>
            </Form>
          </TabPane>
        </Tabs>
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default UserLogin;
