import request from './request';

/**
 * @description get home info
 */
export const getHomeInfo = async () => {
  const response = await request({
    url: '/api/home/info',
    method: 'get',
    token: true,
  });

  return response.data.content;
};

export const getFormAreaDetail = async (formAreaName, dataId) => {
  const response = await request({
    url: `/api/${formAreaName}/get/${dataId}`,
    method: 'get',
    token: true,
  });

  return response.data.content;
};

export const saveFormArea = async (formAreaName, data) => {
  const response = await request({
    url: `/api/${formAreaName}/save`,
    method: 'post',
    token: true,
    data,
  });

  return response.data.content;
};

export const saveFormAreaList = async (formAreaName, dataId) => {
  const response = await request({
    url: `/api/${formAreaName}/list`,
    method: 'post',
    token: true,
    data: { dataId },
  });

  return response.data.content;
};

export const getYears = async () => {
  const response = await request({
    url: '/api/reportYear/add',
    method: 'get',
    token: true,
  });

  return response.data;
};
