import request from './request';

/**
 * get holser list
 * @param {*} params
 * @returns - list
 */
export const getAccountsList = async (params) => {
  const response = await request({
    url: '/api/AccManager/GetAccountList',
    method: 'get',
    params,
    token: true,
  });

  return response.data.content;
};

/**
 * get holser list
 * @param {*} data
 * @returns - list
 */

export const createOrUpdateAccount = async (data) => {
  const response = await request({
    url: '/api/AccManager/CreateOrUpdateAccount',
    method: 'post',
    token: true,
    data,
  });

  return response.data.content;
};

/**
 * reset user password
 * @param {*} data
 * @returns - true or false
 */
export const resetPassword = async (data) => {
  const response = await request({
    url: '/api/AccManager/ResetPassword',
    method: 'post',
    data,
    token: true,
  });

  return response.data?.content;
};
