import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Table,
  Badge,
  Button,
  Modal,
  Input,
  Form,
  Select,
  List,
  InputNumber,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './../../scss/modules/list.module.scss';
import { hasFieldRole, numberWithCommas, STATUS, ROLE } from './../../common';
import { getHolderNames } from './../../api';

const EditableContext = React.createContext(null);

const tableConfig = {
  holder: {
    columns: [
      {
        key: 'holderName',
        link: true,
        width: 180,
        fixed: 'left',
      },
      {
        key: 'enHolderName',
        width: 180,
        fixed: 'left',
      },
      {
        key: 'domesticAgentName',
        width: 150,
      },
      {
        key: 'holderType',
        width: 100,
      },
      {
        key: 'attachmentsNumber',
        width: 100,
      },
      {
        key: 'drugsNumber',
        width: 100,
        render: (text) => numberWithCommas(text),
      },
      // {
      //   key: 'status',
      //   filter: true,
      //   width: 120,
      //   fixed: 'right',
      // },
      // {
      //   key: 'stepStatus',
      //   width: 280,
      //   fixed: 'right',
      // },
    ],
  },
  drugs: {
    columns: [
      {
        key: 'authorizationNo', // 药品批准文号
        link: true,
        width: 180,
        fixed: 'left',
      },
      {
        key: 'genericName', // 药品通用名称
        width: 150,
        fixed: 'left',
      },
      {
        key: 'productName', // 商品名
        width: 150,
        fixed: 'left',
      },
      {
        key: 'specifications', // 规格
        width: 150,
        fixed: 'left',
      },
      {
        key: 'packingSpecifications', // 剂型 --> 包装规格
        width: 120,
      },
      {
        key: 'productionBatches', // 生产/进口批次
        width: 120,
      },
      {
        key: 'productionNumber', // 生产/进口数量
        width: 180,
        // render: (text) => numberWithCommas(text),
      },
      {
        key: 'isProduceInYear', // 年度内是否生产
        width: 130,
      },
      {
        key: 'isProduceInFiveYear', // 5年内是否生产
        width: 130,
      },
      {
        key: 'domesticSales', // 境内销售数量
        width: 180,
        // render: (text) => numberWithCommas(text),
      },
      {
        key: 'quantityUnit', // 生产类型 --> 数量单位
        width: 120,
      },
      {
        key: 'holderName', // 持有人名称
        width: 150,
      },
      {
        key: 'drugType', // 境内/境外
        width: 120,
      },
      // {
      //   key: 'status',
      //   filter: true,
      //   width: 120,
      //   fixed: 'right',
      // },
      // {
      //   key: 'stepStatus',
      //   width: 300,
      //   fixed: 'right',
      // },
    ],
  },
};

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  editable,
  dataIndex,
  title,
  record,
  language,
  type,
  index,
  children,
  handleSave,
  valueType,
  valueEnum,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const labelKey = language === 'cn' ? 'label' : 'enLabel';
  let childNode = children;
  let isEditable = editable;
  let fieldName = dataIndex;

  if (type === 'holder') {
    const isOverseas = ['Overseas', '境外'].indexOf(record.holderType) > -1;
    if (['enHolderName', 'domesticAgentName'].indexOf(dataIndex) > -1) {
      isEditable = isOverseas && editable;
    } else if (dataIndex === 'holderName') {
      fieldName = isOverseas ? 'cnHolderName' : 'holderName';
    }
  }

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const getValByLabel = (label) =>
    valueEnum.find((item) => item[labelKey] === label)?.value;
  const getLabelByVal = (value) =>
    valueEnum.find((item) => item.value === value)?.[labelKey];

  const toggleEdit = () => {
    setEditing(!editing);
    let formValue = record[fieldName];
    if (valueType === 'select') {
      formValue = getValByLabel(formValue);
    }

    form.setFieldsValue({
      [fieldName]: formValue,
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      const [key] = Object.keys(values);
      const [value] = Object.values(values);
      let label = null;
      if (valueType === 'select') {
        label = getLabelByVal(value);
      }

      await handleSave({ dataField: key, value, id: record.id, label });
    } finally {
      toggleEdit();
    }
  };

  if (isEditable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={fieldName}
      >
        {valueType === 'select' ? (
          <Select ref={inputRef} onChange={save} onBlur={save}>
            {valueEnum.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item[labelKey]}
              </Select.Option>
            ))}
          </Select>
        ) : ['domesticSales', 'productionNumber'].indexOf(fieldName) !== -1 ? (
          <InputNumber
            ref={inputRef}
            min={0}
            formatter={(value) => numberWithCommas(value)}
            onPressEnter={save}
            onBlur={save}
          />
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onDoubleClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const ListTable = (props) => {
  const { t, i18n } = useTranslation();
  const {
    type,
    loading,
    filteredValue,
    dataSource,
    role,
    editFields,
    editFieldsEntity,
    stepsInfos,
    pagination,
    onChange,
    permissions,
    currentYear,
    onEditHolderNames,
    onExport,
    onDelete,
    onSave,
    onBasicChaptersCommit,
  } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [holderList, setHolderList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [holderNameForm] = Form.useForm();

  useEffect(() => {
    if (!loading) {
      setSelectedRowKeys([]);
    }
  }, [loading]);

  const columns = tableConfig[type].columns.map((item) => {
    const { link, filter } = item;
    item.title = t(`${type}.${item.key}`);
    item.dataIndex = item.key;
    item.editable =
      editFields.findIndex(
        ({ dataField, formItemId }) =>
          dataField === item.key && hasFieldRole(formItemId, permissions)
      ) !== -1 && editFields.length;
    if (link) {
      if (type === 'holder') {
        item.render = (text, record) => {
          const isOverseas =
            i18n.language === 'cn'
              ? record.holderType === '境外'
              : record.holderType === 'Overseas';
          return (
            <Link to={`/detail/holder/${record.id}`}>
              {isOverseas ? record.cnHolderName : text}
            </Link>
          );
        };
      } else {
        item.render = (text, record) => (
          <Link to={`/detail/drug/${record.id}`}>{text}</Link>
        );
      }
    }
    if (filter) {
      item.filters = [
        {
          text: t('common.inProgress'),
          value: 1,
          status: 'processing',
        },
        {
          text: t('common.complete'),
          value: 2,
          status: 'success',
        },
      ];
      item.filteredValue = filteredValue;
      item.defaultFilteredValue = STATUS.TOTAL;
      item.filterResetToDefaultFilteredValue = true;
      item.render = (state) => {
        return (
          <Badge
            status={['processing', 'success'][state - 1]}
            text={t(`common.${['inProgress', 'complete'][state - 1]}`)}
          />
        );
      };
    }

    if (item.key === 'stepStatus' && stepsInfos?.length) {
      item.render = (text, record) => {
        const stepStatus = text.split(',').map((step) => +step);
        const isEn = i18n.language === 'en';
        const isOverseas = ['Overseas', '境外'].indexOf(record.holderType) > -1;
        const steps =
          type === 'holder' && isOverseas
            ? stepsInfos.filter((item) => !item.holderType)
            : stepsInfos;

        return (
          <List
            dataSource={steps.map((item) => (isEn ? item.enName : item.name))}
            renderItem={(item, index) => (
              <List.Item className={styles.stepListItem}>
                {`${index + 1}. ${item}`}:
                <Badge
                  style={{ marginLeft: 10 }}
                  status={['processing', 'success'][stepStatus[index]]}
                  text={t(
                    `common.${['inProgress', 'complete'][stepStatus[index]]}`
                  )}
                />
              </List.Item>
            )}
          />
        );
      };
    }

    if (item.editable && editFieldsEntity) {
      const fieldEntity = editFieldsEntity.find(
        ({ dataField }) => dataField === item.key
      );

      item.valueType = fieldEntity?.type;
      item.valueEnum = fieldEntity?.options;
    }

    return item;
  });

  if (stepsInfos?.length) {
    stepsInfos.forEach((stepInfo, tableStepIndex) => {
      const isEn = i18n.language === 'en';
      columns.push({
        key: `step${tableStepIndex}`,
        align: 'center',
        width: 235,
        title: (
          <div>
            <strong>{`${t('common.chapter')} ${tableStepIndex + 1}`}</strong>
            <span className={styles.chapterTitle}>
              {isEn ? stepInfo.enName : stepInfo.name}
            </span>
          </div>
        ),
        render: (_, record) => {
          const { stepStatus: status, holderType } = record;
          const stepStatus = status.split(',').map((step) => +step);
          const isOverseas = ['Overseas', '境外'].indexOf(holderType) > -1;
          const stepMaxLength =
            type === 'holder' && isOverseas
              ? stepsInfos.filter((item) => !item.holderType).length
              : stepsInfos.length;
          const index =
            tableStepIndex < stepMaxLength ? stepStatus[tableStepIndex] : 999;

          return (
            <Badge
              status={['processing', 'success'][index]}
              text={t(`common.${['inProgress', 'complete'][index]}`)}
            />
          );
        },
      });
    });
  }

  const handleDelete = () => {
    Modal.confirm({
      title: t('common.confirm'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteMutipleSelected') + t(`common.${type}Records`),
      okText: t('common.yes'),
      cancelText: t('common.no'),
      onOk: () => onDelete(selectedRowKeys),
    });
  };

  const handleSelectChange = (keys) => {
    setSelectedRowKeys(keys);
  };

  const handleGetHolderList = async () => {
    try {
      const { model } = await getHolderNames({ year: currentYear });
      setHolderList(model);
      setVisible(true);
    } catch (err) {
    } finally {
      // dispatch({ type: 'loading', payload: false });
    }
  };

  const handleEditHolderNames = async () => {
    const { holderId } = await holderNameForm.validateFields();
    if (!holderId) return;
    await onEditHolderNames({
      drugIds: selectedRowKeys,
      holderId,
    });
    setVisible(false);
    holderNameForm.resetFields();
  };

  const handleCloseModal = () => {
    setVisible(false);
    holderNameForm.resetFields();
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        type,
        language: i18n.language,
        dataIndex: col.dataIndex,
        title: col.title,
        valueType: col.valueType,
        valueEnum: col.valueEnum,
        editable:
          i18n.language === 'cn'
            ? col.editable
            : [ROLE.ADMIN, ROLE.MANAGER].indexOf(role) !== -1,
        handleSave: onSave,
      }),
    };
  });

  const renderFooterRightActions = (
    <>
      {i18n.language === 'cn' && (
        <Button
          type="primary"
          onClick={() => onBasicChaptersCommit(selectedRowKeys)}
        >
          {`${t('button.submit_' + type)} (${selectedRowKeys.length})`}
        </Button>
      )}

      <Button type="primary" onClick={() => onExport(selectedRowKeys)}>
        {`${t('button.export')} (${selectedRowKeys.length})`}
      </Button>
    </>
  );

  return (
    <>
      <div className={styles.wrapper}>
        <Table
          scrollToFirstRowOnChange
          className={styles.table}
          scroll={{ x: 1000, y: 680 }}
          rowClassName={() => 'editable-row'}
          loading={loading}
          components={components}
          columns={mergedColumns}
          dataSource={dataSource}
          rowSelection={
            editFields.length
              ? {
                  selectedRowKeys,
                  onChange: handleSelectChange,
                }
              : undefined
          }
          pagination={pagination}
          onChange={onChange}
          rowKey={(record) => record.id}
        />
      </div>
      {selectedRowKeys.length > 0 && editFields.length && (
        <div className={styles.footer}>
          {type === 'holder' &&
            [ROLE.ADMIN, ROLE.MANAGER].indexOf(role) !== -1 && (
              <div>{renderFooterRightActions}</div>
            )}
          {type === 'drugs' && (
            <>
              <div>
                {([ROLE.ADMIN, ROLE.MANAGER].indexOf(role) !== -1 &&
                  i18n.language === 'en') ||
                  (i18n.language === 'cn' && (
                    <Button
                      type="primary"
                      onClick={handleGetHolderList}
                      disabled={
                        [ROLE.ADMIN, ROLE.MANAGER].indexOf(role) === -1 &&
                        i18n.language === 'en'
                      }
                    >
                      {`${t('button.editHolderName')} (${
                        selectedRowKeys.length
                      })`}
                    </Button>
                  ))}
                {[ROLE.ADMIN, ROLE.MANAGER].indexOf(role) !== -1 &&
                  renderFooterRightActions}
              </div>
              <Modal
                title={t(`drugs.holderName`)}
                visible={visible}
                okText={t('button.save')}
                cancelText={t('button.cancel')}
                onCancel={handleCloseModal}
                onOk={handleEditHolderNames}
              >
                <Form form={holderNameForm}>
                  <Form.Item
                    name="holderId"
                    label={t(`drugs.holderName`)}
                    className={styles.item}
                  >
                    <Select
                      placeholder={t('form.select')}
                      fieldNames={{ label: 'holderName', value: 'id' }}
                      options={holderList}
                    />
                  </Form.Item>
                </Form>
              </Modal>
            </>
          )}
          <Button type="primary" danger onClick={handleDelete}>
            {`${t('button.delete')} (${selectedRowKeys.length})`}
          </Button>
        </div>
      )}
    </>
  );
};

export default withTranslation()(ListTable);
