import request from './request';

/**
 * get holser list
 * @param {string} status
 * @returns - list
 */
export const getLogList = async (params) => {
  const response = await request({
    url: '/api/log/list',
    method: 'get',
    params,
    token: true,
  });

  return response.data.content;
};
