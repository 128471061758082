import { Card } from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';
import styles from './../../scss/modules/card.module.scss';
import { STATUS } from './../../common';

const list = [
  {
    name: 'total',
    status: STATUS.TOTAL,
    countKey: 'totalCount',
  },
  {
    name: 'inProgress',
    status: STATUS.PROCESS,
    countKey: 'draftCount',
  },
  {
    name: 'complete',
    status: STATUS.FINISHED,
    countKey: 'finishCount',
  },
];

const CardList = ({ data, currentStatus, onClick }) => {
  const { t } = useTranslation();

  const items = list.map((item) => {
    let count = data[item.countKey] || 0;

    const status = item.name === 'total' ? STATUS.TOTAL : item.status;

    return (
      <Card.Grid
        key={item.name}
        className={`${styles.grid} ${
          currentStatus.join() === status.join() ? styles.active : null
        }`}
        style={{ width: '33%' }}
        onClick={() => onClick(status)}
      >
        <div className={styles.text}>{t(`common.${item.name}`)}</div>
        <div className={styles.count}>{count}</div>
      </Card.Grid>
    );
  });

  return <Card className={styles.card}>{items}</Card>;
};

export default withTranslation()(CardList);
