import { useState, useEffect, useContext } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, Modal, message as Message } from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';
import jsFileDownload from 'js-file-download';
import {
  FormSideRight,
  FormTemplate,
  ProductionSalesInfoList,
  ChangeManagements,
  SubmitModal,
} from './../components';
import styles from './../scss/modules/form.module.scss';
import {
  getDrugDetail,
  saveDrug,
  fileUpload,
  getFileList,
  fileDelete,
  fileDownload,
  fileUpdate,
  getChangemanagementsList,
  saveChangemanagements,
  getProductionsales,
  saveProductionsales,
  saveComments,
  getCommentList,
} from '../api';
import {
  formatFormValues,
  AppContext,
  STEPINFO,
  FORMINFO,
  LANGUAGE,
  ROLE,
  useQuery,
} from './../common';

const DrugForm = () => {
  const language = useQuery().get('language') || 'cn';
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const { id } = useParams();
  const {
    state: {
      formTemplate: { drugs },
      user: { permissions, roleId },
      years,
    },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [list, setList] = useState(null);
  const [stepStatus, setStepStatus] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [changemanagementsListValues, setChangemanagementsListValues] =
    useState({});
  const [productionsalesListValues, setProductionsalesListValues] = useState(
    {}
  );
  const [comments, setComments] = useState([]);
  const [modalProps, setModalProps] = useState({
    type: '',
    visible: false,
    data: {},
  });
  const [drawerProps, setDrawerProps] = useState({
    visible: false,
    dataField: null,
    fieldId: null,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isSave, setIsSave] = useState({
    visible: false,
    next: -1,
    isSubmit: true,
  });
  const [changedFormValues, setChangedFormValues] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);

  useEffect(() => {
    if (!drugs) return;
    dispatch({ type: 'current', payload: 'drugsDetail' });
    setList(drugs);
    return () => {
      setList(null);
      setFormValues({});
      setCurrentStep(0);
    };
  }, [id, pathname, drugs]);

  const initPageData = async () => {
    await initFormValues();

    if (currentStep === 1) {
      initProductionsalesData();
    } else if (currentStep === 2) {
      initChangemanagementsData();
    }
  };

  useEffect(() => {
    if (!list) return;
    form.resetFields();
    initPageData();
    getFieldsComment();
    if ([2, 3].indexOf(currentStep) > -1) {
      getFileListByStepId(list?.steps[currentStep].id);
    }
  }, [currentStep, language, list]);

  useEffect(() => {
    if (isSave.visible) {
      handleSaveForm({});
    }
  }, [isSave]);

  const initFormValues = async () => {
    if (pathname === '/create/drug') {
      let defaultValues = formatFormValues(list?.fields);
      form.setFieldsValue(defaultValues);
      setFormValues(defaultValues);
      setStepStatus(Array(list?.steps?.length).fill(STEPINFO.WAIT));
    } else if (id) {
      dispatch({ type: 'loading', payload: true });
      try {
        const { model } = await getDrugDetail({
          dataId: id,
          language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
        });
        let defaultValues = formatFormValues(list?.fields, model);
        setFormValues(defaultValues);
        form.setFieldsValue(defaultValues);
        setStepStatus(
          model.stepStatus.split(',').map((item) => parseInt(item))
        );
        setFormDisabled(model.year !== years[0]);
      } catch (error) {
      } finally {
        dispatch({ type: 'loading', payload: false });
      }
    }
  };

  const getFieldsComment = async () => {
    if (!id) return;
    dispatch({ type: 'loading', payload: true });
    try {
      const { model } = await getCommentList({
        formId: FORMINFO.DRUG,
        dataId: id,
        inputAreaId: list?.steps[currentStep].id || 7,
      });
      setComments(model);
    } catch (error) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const isRequiredFieldIsNull = () => {
    if (!id && i18n.language === 'en') {
      return true;
    } else if (i18n.language === 'en') {
      return false;
    }

    const { authorizationNo, genericName } = formValues;
    if (!authorizationNo) {
      Message.error(t('drugs.authorizationNo') + '为空');
      return true;
    } else if (!genericName) {
      Message.error(t('drugs.genericName') + '为空');
      return true;
    }

    return false;
  };

  const handleFinish = async (values, isSubmit = true) => {
    if (isRequiredFieldIsNull()) return;
    setIsSave({
      visible: true,
      next: -1,
      isSubmit,
    });
    setChangedFormValues(values);
  };

  const handleStepChange = async (index) => {
    if (isEdit) {
      Modal.confirm({
        title: t('common.confirm'),
        icon: <ExclamationCircleOutlined />,
        content: t('common.isChange'),
        okText: t('common.yes'),
        cancelText: t('common.no'),
        onOk: async (close) => {
          try {
            // await form.validateFields();
            if (isRequiredFieldIsNull()) return;
            setIsSave({
              visible: true,
              next: index,
              isSubmit: false,
            });
          } finally {
            close();
          }
        },
        onCancel: () => {
          setCurrentStep(index);
          setIsEdit(false);
        },
      });
    } else {
      setCurrentStep(index);
    }
  };

  const getFileListByStepId = async (stepId) => {
    if (!id) return;
    dispatch({ type: 'loading', payload: true });
    try {
      const { model } = await getFileList({
        formId: FORMINFO.DRUG,
        dataId: id,
        inputAreaId: stepId,
      });
      setFileList(
        model.map((item) => {
          return {
            ...item,
            name: item.fileName,
            url: item.uri,
            status: 'done',
          };
        })
      );
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleFileUpload = async (
    { data: { dataField, fieldId }, file },
    listInfo
  ) => {
    dispatch({ type: 'loading', payload: true });
    try {
      await fileUpload({
        formId: FORMINFO.DRUG,
        file,
        dataField: listInfo ? listInfo.dataField : dataField,
        fieldId: listInfo ? listInfo.listId : fieldId,
        dataId: id,
      });
      Message.success('操作成功');
      getFileListByStepId(list?.steps[currentStep].id);
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleFileRemove = async (file) => {
    Modal.confirm({
      title: t('common.confirm'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteFile'),
      okText: t('common.yes'),
      cancelText: t('common.no'),
      onOk: async () => {
        dispatch({ type: 'loading', payload: true });
        try {
          await fileDelete(file.id);
          Message.success('操作成功');
          getFileListByStepId(list?.steps[currentStep].id);
        } finally {
          dispatch({ type: 'loading', payload: false });
        }
      },
    });
  };

  const handleFileDownload = async (file) => {
    dispatch({ type: 'loading', payload: true });
    try {
      const result = await fileDownload(file.id);
      jsFileDownload(result, file.name);
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleEditFileInfo = ({ id, fileNumber, versionNumber }) => {
    setModalProps({
      type: 'file',
      visible: true,
      data: {
        fileId: id,
        fileNumber,
        versionNumber,
      },
    });
  };

  const handleValueChange = (changedValues, allValues) => {
    if (
      i18n.language === 'cn' ||
      (i18n.language === 'en' &&
        [ROLE.ADMIN, ROLE.MANAGER].indexOf(roleId) !== -1)
    ) {
      setIsEdit(true);
    }
    setFormValues({ ...formValues, ...changedValues });
    setChangedFormValues(allValues);
  };

  const handleListValuesChange = (changedValues) => {
    if (
      i18n.language === 'cn' ||
      (i18n.language === 'en' &&
        [ROLE.ADMIN, ROLE.MANAGER].indexOf(roleId) !== -1)
    ) {
      setIsEdit(true);
    }
    setChangedFormValues(changedValues);
  };

  const initChangemanagementsData = async () => {
    if (!id) return;
    dispatch({ type: 'loading', payload: true });
    try {
      const { model } = await getChangemanagementsList({
        dataId: id,
        language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
      });
      setChangemanagementsListValues(model);
    } catch (error) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleChangeManagementsFinish = async (values, isSubmit = true) => {
    if (isRequiredFieldIsNull()) return;
    setIsSave({
      visible: true,
      next: -1,
      isSubmit,
    });
    setChangedFormValues(values);
  };

  const initProductionsalesData = async () => {
    if (!id) return;
    dispatch({ type: 'loading', payload: true });
    try {
      const { model } = await getProductionsales({
        dataId: id,
        language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
      });
      setProductionsalesListValues(model);
    } catch (error) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleProductionSalesFinish = async (values, isSubmit = true) => {
    if (isRequiredFieldIsNull()) return;
    setIsSave({
      visible: true,
      next: -1,
      isSubmit,
    });
    setChangedFormValues(values);
  };

  const handleAddComment = (dataField, fieldId) => {
    setModalProps({
      type: 'comment',
      visible: true,
      data: {
        dataField,
        fieldId,
      },
    });
  };

  const handleShowComments = (dataField, fieldId) => {
    setDrawerProps({
      visible: true,
      dataField,
      fieldId,
    });
  };

  const handleModalSubmit = async (values) => {
    try {
      dispatch({ type: 'loading', payload: true });
      const { data, type } = modalProps;
      if (type === 'comment') {
        await saveComments({
          formId: FORMINFO.DRUG,
          dataId: id,
          fieldId: data?.fieldId,
          dataField: data?.dataField,
          comment: values[data?.dataField],
        });
        getFieldsComment();
      } else {
        await fileUpdate({
          id: data?.fileId,
          ...values,
        });
        getFileListByStepId(list?.steps[currentStep].id);
      }

      Message.success('操作成功');
      setModalProps({
        type: '',
        visible: false,
        data: {},
      });
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleSaveForm = async (values) => {
    const next = isSave.next;
    const isSubmit = isSave.isSubmit;
    if (next !== -1) {
      Message.info('正在保存修改');
    }
    setIsSave({
      visible: false,
      next: -1,
      isSubmit: true,
    });
    dispatch({ type: 'loading', payload: true });
    try {
      if (currentStep === 1) {
        await saveProductionsales(
          {
            drugId: id,
            ...changedFormValues,
            ...values,
          },
          {
            language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
            isSubmit,
          }
        );
        Message.success('操作成功');
        initProductionsalesData();
      } else if (currentStep === 2) {
        await saveChangemanagements(
          {
            drugId: id,
            ...changedFormValues,
            ...values,
          },
          {
            language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
            isSubmit,
          }
        );
        Message.success('操作成功');
        initChangemanagementsData();
        getFileListByStepId(list?.steps[currentStep].id);
      } else {
        const { model: formId } = await saveDrug(
          {
            ...values,
            ...changedFormValues,
            needVerify: true,
            id: id || 0,
          },
          {
            inputAreaId: list?.steps[currentStep].id,
            language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
            isSubmit,
          }
        );
        Message.success('操作成功');
        if (!id) {
          navigate(`/detail/drug/${formId}`);
          const { model } = await getDrugDetail({
            dataId: formId,
            language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
          });
          let defaultValues = formatFormValues(list?.fields, model);
          setFormValues(defaultValues);
          form.setFieldsValue(defaultValues);
        }
        initFormValues();
        window.scrollTo(0, 0);
      }
      setIsEdit(false);
      if (next !== -1) {
        setCurrentStep(next);
      }
    } catch (error) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleCancelSaveForm = () => {
    setIsSave({
      visible: false,
      next: -1,
      isSubmit: true,
    });
  };

  const getSubTitle = () => {
    const fieldKeyList = [
      'authorizationNo',
      'genericName',
      'productName',
      'specifications',
    ];
    const subTitle = [];
    fieldKeyList.forEach((key) => {
      if (formValues[key]) {
        subTitle.push(formValues[key]);
      }
    });
    return !!subTitle.length ? subTitle.join(' - ') : '';
  };

  const title = `${currentStep + 1}. ${
    i18n.language === 'cn'
      ? list?.steps[currentStep].name
      : list?.steps[currentStep].enName
  }`;

  // Common form template props.
  const commonProps = {
    form,
    title,
    subTitle: getSubTitle(),
    isEdit,
    isTranslate: [ROLE.ADMIN, ROLE.MANAGER].indexOf(roleId) !== -1,
    permissions: permissions.drugs,
    onNext: handleStepChange,
  };

  // Commit props.
  const commentProps = {
    isAddComment: !!id,
    comments,
    onAddComment: handleAddComment,
    onShowComments: handleShowComments,
  };

  // File props.
  const fileProps = {
    isUpload: !!id,
    fileList,
    onFileUpload: handleFileUpload,
    onFileRemove: handleFileRemove,
    onFileDownload: handleFileDownload,
    onEditFileInfo: handleEditFileInfo,
  };

  const formTemplateProps = {
    ...commonProps,
    ...commentProps,
    ...fileProps,
    groups: list?.groups.filter(({ stepIndex }) => stepIndex === currentStep),
    fields: list?.fields.filter(({ stepIndex }) => stepIndex === currentStep),
    currentStep: currentStep < list?.steps.length - 1 ? currentStep : -1,
    values: formValues,
    onValuesChange: handleValueChange,
    onFinish: handleFinish,
  };

  const productionSalesInfoListProps = {
    ...commonProps,
    ...commentProps,
    currentStep,
    groupItem: list?.fields.find(({ stepIndex }) => stepIndex === currentStep),
    groupFields: list?.fields.filter(
      ({ stepIndex }) => stepIndex === currentStep
    ),
    productionsalesListValues: productionsalesListValues,
    onFinish: handleProductionSalesFinish,
    onValuesChange: handleListValuesChange,
  };

  const changeManagementsProps = {
    ...commonProps,
    ...commentProps,
    ...fileProps,
    currentStep,
    groupItems: list?.fields.filter(
      ({ stepIndex }) => stepIndex === currentStep
    ),
    changemanagementsListValues: changemanagementsListValues,
    onValuesChange: handleListValuesChange,
    onFinish: handleChangeManagementsFinish,
  };

  return (
    <div className={`${styles.wrapper} form-section`}>
      {list && list?.steps.length > 0 && (
        <>
          {list?.steps[currentStep].type === 'Default' && (
            <FormTemplate {...formTemplateProps} formDisabled={formDisabled} />
          )}
          {currentStep === 1 && (
            <ProductionSalesInfoList
              {...productionSalesInfoListProps}
              formDisabled={formDisabled}
            />
          )}
          {currentStep === 2 && (
            <ChangeManagements
              {...changeManagementsProps}
              formDisabled={formDisabled}
            />
          )}
          <FormSideRight
            current={currentStep}
            steps={list?.steps}
            status={stepStatus}
            modalConfig={{
              ...modalProps,
              onModalSubmit: handleModalSubmit,
              onCancel: () => {
                setModalProps({
                  type: '',
                  visible: false,
                  data: {},
                });
              },
            }}
            drawerConfig={{
              ...drawerProps,
              commentList: comments.filter(
                (item) =>
                  item.dataField === drawerProps.dataField &&
                  item.fieldId === drawerProps.fieldId
              ),
              onClose: () => {
                setDrawerProps({
                  visible: false,
                  dataField: null,
                  fieldId: null,
                });
              },
            }}
            onStepChange={handleStepChange}
          />
          {/* <SubmitModal
            visible={isSave.visible}
            onFinish={handleSaveForm}
            onCancel={handleCancelSaveForm}
          /> */}
        </>
      )}
    </div>
  );
};

export default withTranslation()(DrugForm);
