import { Form, Button, Modal } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import FormItem from './FormItem';
import { hasFieldRole, changeValueType, sortCompare } from './../../common';
import styles from './../../scss/modules/form.module.scss';
import { useEffect, useState } from 'react';

const ProductionSalesInfoList = (props) => {
  const { t, i18n } = useTranslation();
  const {
    form,
    title,
    subTitle,
    isEdit,
    isTranslate,
    currentStep,
    groupFields,
    permissions,
    productionsalesListValues,
    comments,
    isAddComment,
    onAddComment,
    onShowComments,
    onFinish,
    onNext,
    onValuesChange,
    formDisabled,
  } = props;
  const [valuesList, setValuesList] = useState({});
  const [fieldList, setFieldList] = useState({
    exportSalesInfoList: [],
  });
  const fieldListKeys = Object.keys(fieldList);
  const normalFields = groupFields.filter(
    (item) => fieldListKeys.indexOf(item.dataField) === -1
  );

  useEffect(() => {
    normalFields.forEach((fieldItem) => {
      const { dataField, type } = fieldItem;
      let value = productionsalesListValues?.[dataField];
      if (dataField === 'isProduceInYear') {
        value = +productionsalesListValues.productionNumber ? 'Yes' : 'No';
      }

      value = changeValueType(value, type);
      productionsalesListValues[dataField] = value;
      form.setFieldValue(dataField, value);
    });

    const newFieldList = { ...fieldList };
    fieldListKeys.forEach((key) => {
      const groupItem = groupFields.find((item) => item.dataField === key);
      const arrLen = productionsalesListValues[key]?.length;
      newFieldList[key] = Array(arrLen || 1).fill(groupItem);
      if (arrLen) {
        productionsalesListValues[key].forEach((item, index) => {
          setFieldValue(groupItem, index, item);
        });
      } else {
        productionsalesListValues[key] = [initFieldValue(groupItem)];
      }
    });

    setFieldList(newFieldList);
    setValuesList({
      ...productionsalesListValues,
    });
  }, [productionsalesListValues]);

  const setFieldValue = (list, index, valueObj) => {
    const childrenItems = list.childrenItemList.filter(
      (item) => !item.dataField.includes('upload')
    );
    childrenItems.forEach(({ dataField, type }) => {
      const value = changeValueType(valueObj[dataField], type);
      form.setFieldValue(`${dataField}-${list.dataField}-${index}`, value);
    });
  };

  const initFieldValue = (list) => {
    const childrenItems = list.childrenItemList
      // .map((item) => item.dataField)
      .filter((item) => !item.dataField.includes('upload'));
    let initValues = {};
    childrenItems.forEach((item) => {
      const { dataField, defaultValue, type } = item;
      initValues[dataField] = changeValueType(defaultValue, type);
    });
    return initValues;
  };

  const handleValuesChange = (changedValues) => {
    const normalFieldKeys = normalFields.map((item) => item.dataField);
    const [key] = Object.keys(changedValues);
    const [value] = Object.values(changedValues);

    if (normalFieldKeys.indexOf(key) === -1) {
      const [dataField, parentField, index] = key.split('-');
      const list = [...valuesList[parentField]];
      list[index][dataField] = value;
      setValuesList({
        ...valuesList,
        [parentField]: list,
      });
      onValuesChange({
        ...valuesList,
        [parentField]: list,
      });
    } else {
      const newValues = { ...valuesList };
      newValues[key] = value;
      if (key === 'productionNumber') {
        const isProduceInYear = +value === 0 ? 'No' : 'Yes';
        newValues['isProduceInYear'] = isProduceInYear;
        form.setFieldValue('isProduceInYear', isProduceInYear);
      }
      setValuesList(newValues);
      onValuesChange(newValues);
    }
  };

  const handleDeleteFields = (listDataField, index) => {
    Modal.confirm({
      title: t('common.confirm'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteRecord'),
      okText: t('common.yes'),
      cancelText: t('common.no'),
      onOk: async () => {
        // Remove item to fields.
        const newFieldsList = [...fieldList[listDataField]];
        const prevListLength = newFieldsList.length;
        newFieldsList.splice(index, 1);
        setFieldList({
          ...fieldList,
          [listDataField]: newFieldsList,
        });

        // Remove value to value list.
        const newValuesList = [...valuesList[listDataField]];
        newValuesList.splice(index, 1);
        setValuesList({
          ...valuesList,
          [listDataField]: newValuesList,
        });

        // Reset values
        const groupItem = groupFields.find(
          (item) => item.dataField === listDataField
        );
        newValuesList.forEach((item, valueIndex) => {
          setFieldValue(groupItem, valueIndex, item);
        });
        // reset deleted group fileds value
        setFieldValue(groupItem, prevListLength - 1, {});

        onValuesChange({
          ...valuesList,
          [listDataField]: newValuesList,
        });
      },
    });
  };

  const handleAddFields = (dataField, index) => {
    const list = [...fieldList[dataField]];
    const groupItem = groupFields.find((item) => item.dataField === dataField);
    list.splice(index + 1, 0, groupItem);

    setFieldList({
      ...fieldList,
      [dataField]: list,
    });

    const newValues = [...valuesList[dataField]];
    newValues.splice(index + 1, 0, initFieldValue(groupItem));
    setValuesList({
      ...valuesList,
      [dataField]: newValues,
    });
  };

  const renderListGroup = (list, parentDisabled) =>
    list.map((listItem, listIndex) => {
      const { dataField: listDataField, childrenItemList } = listItem;
      return (
        <div
          key={`${listDataField}-${listIndex}`}
          className={`${styles.group}`}
        >
          {list.length > 1 && !parentDisabled && (
            <Button
              className={styles.deleteIcon}
              size="small"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteFields(listDataField, listIndex)}
            />
          )}

          {childrenItemList
            .sort(sortCompare('sort'))
            .map((child, childIndex) => {
              return (
                <FormItem
                  {...child}
                  key={`${child.dataField}-${listDataField}-${listIndex}`}
                  dataField={`${child.dataField}-${listDataField}-${listIndex}`}
                  required={child.required}
                  isHidden={
                    child.parentField
                      ? valuesList[listDataField][childIndex][
                          child.parentField
                        ] !== child.parentValue
                      : false
                  }
                  readOnly={
                    i18n.language === 'en'
                      ? child.readOnly || !isTranslate
                      : child.readOnly
                  }
                  disabled={
                    parentDisabled ||
                    child.disabled ||
                    !hasFieldRole(listItem.id, permissions) ||
                    formDisabled
                  }
                  isAddComment={
                    isAddComment && valuesList[listDataField][listIndex]?.id
                  }
                  comments={comments.filter(
                    (comment) =>
                      comment.fieldId ===
                        valuesList[listDataField][listIndex]?.id &&
                      comment.dataField === child.dataField
                  )}
                  onAddComment={() =>
                    onAddComment(
                      child.dataField,
                      valuesList[listDataField][listIndex]?.id
                    )
                  }
                  onShowComments={() =>
                    onShowComments(
                      child.dataField,
                      valuesList[listDataField][listIndex]?.id
                    )
                  }
                />
              );
            })}
        </div>
      );
    });

  return (
    <div className={styles.content}>
      <p className={styles.pageSubTitle}>{title}</p>
      {!!subTitle && <p className={styles.pageSubTitleSmall}>{subTitle}</p>}
      <Form
        form={form}
        size="large"
        colon={false}
        layout="vertical"
        scrollToFirstError={true}
        onValuesChange={handleValuesChange}
        onFinish={() => onFinish(valuesList)}
      >
        <div className={styles.group}>
          {groupFields.sort(sortCompare('sort')).map((field) => {
            const disabled =
              field.disabled ||
              !hasFieldRole(field.id, permissions) ||
              formDisabled;
            const { dataField, parentField, parentValue } = field;

            if (fieldListKeys.indexOf(dataField) > -1) {
              if (valuesList[parentField] !== parentValue) return null;

              return !!fieldList[dataField]?.length ? (
                <div
                  key={dataField}
                  style={{ width: '100%' }}
                  className={styles.childGroup}
                >
                  {renderListGroup(fieldList[dataField], disabled)}
                  {!disabled && (
                    <div className={styles.addMoreButtons}>
                      <Button
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() =>
                          handleAddFields(
                            dataField,
                            fieldList[dataField].length - 1
                          )
                        }
                      >
                        {t('form.add')}
                      </Button>
                    </div>
                  )}
                </div>
              ) : null;
            }

            return (
              <FormItem
                {...field}
                initFieldValue={valuesList[dataField]}
                key={dataField}
                required={field.required}
                isHidden={
                  field.parentField
                    ? valuesList[field.parentField] !== field.parentValue
                    : false
                }
                readOnly={
                  i18n.language === 'en'
                    ? field.readOnly || !isTranslate
                    : field.readOnly
                }
                disabled={disabled}
                isAddComment={isAddComment}
                comments={comments.filter(
                  (comment) => comment.fieldId === field.id
                )}
                onAddComment={() => onAddComment(field.dataField, field.id)}
                onShowComments={() => onShowComments(field.dataField, field.id)}
              />
            );
          })}
        </div>
        {!formDisabled && (
          <div className={styles.footer}>
            {i18n.language === 'cn' && (
              <Button size="large" type="primary" htmlType="submit">
                {t('button.submit')}
              </Button>
            )}
            {i18n.language === 'cn' && (
              <Button
                size="large"
                type="primary"
                onClick={() => onFinish(valuesList, false)}
              >
                {t('button.save')}
              </Button>
            )}
            {i18n.language === 'en' && isTranslate && (
              <Button size="large" type="primary" htmlType="submit">
                {t('button.translate')}
              </Button>
            )}
            <Button
              size="large"
              style={{ marginLeft: '20px' }}
              onClick={() => onNext(currentStep + 1)}
            >
              {t('button.next')}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default withTranslation()(ProductionSalesInfoList);
