import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import FormItem from './FormItem';
import { hasFieldRole, initListFormValues, sortCompare } from './../../common';
import styles from './../../scss/modules/form.module.scss';

const DelegationForm = (props) => {
  const { t, i18n } = useTranslation();
  const {
    title,
    subTitle,
    form,
    isEdit,
    isTranslate,
    currentStep,
    permissions,
    delegationList,
    drugsList,
    comments,
    groupItem,
    isAddComment,
    onAddComment,
    onShowComments,
    onFinish,
    onNext,
    onValuesChange,
    formDisabled,
  } = props;
  const [fields, setFields] = useState([]);
  const [valuesList, setValuesList] = useState([]);

  useEffect(() => {
    if (delegationList.delegations.length) {
      setValuesList(delegationList.delegations);
      setFormValues(delegationList.delegations);
    } else {
      setValuesList([
        initListFormValues(
          groupItem.childrenItemList.map((item) => item.dataField)
        ),
      ]);
    }
    setFields(Array(delegationList.delegations.length || 1).fill(groupItem));
  }, [delegationList]);

  const handleValuesChange = (changedValues) => {
    const [key] = Object.keys(changedValues);
    const [value] = Object.values(changedValues);
    const [dataField, index] = key.split('-');
    const newValues = { ...valuesList[index] };

    // 药品通用名称改变，药品信息自动填入
    if (dataField === 'drugId') {
      const keys = [
        'dosageForm',
        'specifications',
        'productionAddress',
        'productionWorkshop',
        'productionLine',
      ];
      // Get data form drugsList
      const [currentItem] = drugsList.filter(({ id }) =>
        value ? id.toString() === value.toString() : false
      );
      keys.forEach((item) => {
        form.setFieldValue(
          `${item}-${index}`,
          value ? currentItem[item] : undefined
        );
        newValues[item] = value ? currentItem[item] : undefined;
      });
      newValues.drugId = value;
    } else if (dataField === 'delegateHolderId') {
      // Get holderAddress form holderOptions
      const [currentItem] = delegationList.holderOptions.filter(
        (item) => item.value === value
      );
      form.setFieldValue(
        `delegateCountry-${index}`,
        currentItem?.holderAddress || undefined
      );
      newValues.delegateHolderId = value;
      newValues.delegateCountry = currentItem?.holderAddress || undefined;
    } else {
      newValues[dataField] = value;
    }
    const newValuesList = [...valuesList];
    newValuesList.splice(index, 1, newValues);
    setValuesList(newValuesList);
    onValuesChange(newValuesList);
  };

  const handleAddFields = (index) => {
    // Add item to fields.
    const newFieldsList = [...fields];
    newFieldsList.splice(index + 1, 0, groupItem);
    setFields(newFieldsList);

    // Add value to value list.
    const newValuesList = [...valuesList];
    const newValue = initListFormValues(
      groupItem.childrenItemList.map((item) => item.dataField)
    );
    newValuesList.splice(index + 1, 0, newValue);
    setValuesList(newValuesList);
  };

  const handleDelete = (index) => {
    Modal.confirm({
      title: t('common.confirm'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteRecord'),
      okText: t('common.yes'),
      cancelText: t('common.no'),
      onOk: async () => {
        form.resetFields();
        // Remove item to fields.
        const newFieldsList = [...fields];
        newFieldsList.splice(index, 1);
        setFields(newFieldsList);

        // Remove value to value list.
        const newValuesList = [...valuesList];
        newValuesList.splice(index, 1);
        setValuesList(newValuesList);
        setFormValues(newValuesList);
        onValuesChange(newValuesList);
      },
    });
  };

  const setFormValues = (list) => {
    list.forEach((item, index) => {
      form.setFieldsValue({
        [`productionQuantity-${index}`]: item.productionQuantity,
        [`deliveryQuantity-${index}`]: item.deliveryQuantity,
        [`drugId-${index}`]: item.drugId ? item.drugId.toString() : null,
        [`dosageForm-${index}`]: item.drugId ? item.dosageForm : null,
        [`specifications-${index}`]: item.drugId ? item.specifications : null,
        [`productionAddress-${index}`]: item.drugId
          ? item.productionAddress
          : null,
        [`productionWorkshop-${index}`]: item.drugId
          ? item.productionWorkshop
          : null,
        [`productionLine-${index}`]: item.drugId ? item.productionLine : null,
        [`delegateHolderId-${index}`]: item.delegateHolderId
          ? item.delegateHolderId.toString()
          : null,
        [`delegateCountry-${index}`]: item.delegateHolderId
          ? item.delegateCountry
          : null,
      });
    });
  };

  return (
    <div className={styles.content}>
      <p className={styles.pageSubTitle}>{title}</p>
      {!!subTitle && <p className={styles.pageSubTitleSmall}>{subTitle}</p>}

      {fields.length > 0 && drugsList.length ? (
        <Form
          form={form}
          size="large"
          colon={false}
          layout="vertical"
          scrollToFirstError={true}
          onValuesChange={handleValuesChange}
          onFinish={() => onFinish(valuesList)}
        >
          {fields.map((item, index) => {
            const disabled =
              item.disabled ||
              !hasFieldRole(item.id, permissions) ||
              formDisabled;
            return (
              <div
                key={index}
                className={`${styles.fieldGroup} ${styles.group}`}
              >
                {!disabled && fields.length > 1 && (
                  <Button
                    className={styles.deleteIcon}
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(index)}
                  />
                )}
                {item.childrenItemList
                  .sort(sortCompare('sort'))
                  .map((field) => {
                    if (field.dataField === 'drugId') {
                      field.options = delegationList.drugOptions;
                    } else if (field.dataField === 'delegateHolderId') {
                      field.options = delegationList.holderOptions;
                    }
                    return (
                      <FormItem
                        {...field}
                        dataField={`${field.dataField}-${index}`}
                        key={field.id}
                        readOnly={
                          i18n.language === 'en'
                            ? field.readOnly || !isTranslate
                            : field.readOnly
                        }
                        isAddComment={valuesList[index]?.id && isAddComment}
                        comments={comments.filter(
                          (comment) =>
                            comment.fieldId === valuesList[index]?.id &&
                            comment.dataField === field.dataField
                        )}
                        onAddComment={() =>
                          onAddComment(field.dataField, valuesList[index]?.id)
                        }
                        onShowComments={() =>
                          onShowComments(field.dataField, valuesList[index]?.id)
                        }
                        disabled={disabled}
                        isHidden={
                          field.parentField
                            ? delegationList[`${field.dataField}-${index}`] !==
                              field.parentValue
                            : false
                        }
                      />
                    );
                  })}
              </div>
            );
          })}
          {hasFieldRole(fields[0]?.id, permissions) &&
            !fields[0]?.disabled &&
            !formDisabled && (
              <div className={styles.addMoreButtons}>
                <Button
                  size="small"
                  icon={<PlusOutlined />}
                  onClick={() => handleAddFields(fields.length - 1)}
                >
                  {t('form.add')}
                </Button>
              </div>
            )}
          <div className={styles.footer}>
            {i18n.language === 'cn' && (
              <Button size="large" type="primary" htmlType="submit">
                {t('button.submit')}
              </Button>
            )}
            {i18n.language === 'cn' && (
              <Button
                size="large"
                type="primary"
                onClick={() => onFinish(valuesList, false)}
              >
                {t('button.save')}
              </Button>
            )}
            {i18n.language === 'en' && isTranslate && (
              <Button size="large" type="primary" htmlType="submit">
                {t('button.translate')}
              </Button>
            )}
            <Button
              size="large"
              style={{ marginLeft: '20px' }}
              onClick={() => onNext(currentStep + 1)}
            >
              {t('button.next')}
            </Button>
          </div>
        </Form>
      ) : (
        <>
          <p>持有人表单还未与药品相关联</p>
          <div className={styles.footer}>
            <Button
              size="large"
              style={{ marginLeft: '20px' }}
              onClick={() => onNext(currentStep + 1)}
            >
              {t('button.next')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation()(DelegationForm);
