import {
  setLocalAccessToken,
  setLocalRefreshToken,
  setLocalUserInfo,
  setLocalFormTemplate,
  setLocalImportFields,
  setLocalBasicInforConfirmed,
  getLocalBasicInforConfirmed,
  setLocalYears,
  setLocalStartNewYearDisabled,
} from './util';

export const mainReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'login':
      const {
        accessToken,
        refreshAccessToken,
        name,
        roleId,
        id,
        permissions,
        language,
        responsiblePersonnel,
        isConfirm,
      } = payload;
      const lan = language === 'en-US' ? 'en' : 'cn';
      const user = {
        name,
        roleId,
        id,
        isResponsiblePersonnel: responsiblePersonnel,
        permissions: {
          holder:
            permissions.find((item) => item.formId === 1)?.formItemId || '',
          drugs:
            permissions.find((item) => item.formId === 2)?.formItemId || '',
        },
      };
      setLocalAccessToken(accessToken);
      setLocalRefreshToken(refreshAccessToken);
      setLocalUserInfo(JSON.stringify(user));
      setLocalBasicInforConfirmed(JSON.stringify(isConfirm));
      return { ...state, isAuth: true, user, lan };
    case 'logout':
      localStorage.clear();
      window.location.href = '/auth';
      return { ...state, isAuth: false };
    case 'loading':
      return { ...state, loading: payload };
    case 'formTemplate':
      setLocalFormTemplate(JSON.stringify(payload));
      return { ...state, formTemplate: payload };
    case 'importFields':
      setLocalImportFields(JSON.stringify(payload));
      return { ...state, importFields: payload };
    case 'years':
      setLocalYears(JSON.stringify(payload));
      return { ...state, years: payload };
    case 'currentYear':
      return { ...state, currentYear: payload };
    case 'startNewYearDisabled':
      setLocalStartNewYearDisabled(JSON.stringify(payload));
      return { ...state, startNewYearDisabled: payload };
    case 'current':
      return { ...state, currentPage: payload };
    case 'basicInforChecked':
      const basicInforChecked = {
        ...getLocalBasicInforConfirmed(),
        ...payload,
      };
      setLocalBasicInforConfirmed(JSON.stringify(basicInforChecked));
      return {
        ...state,
        basicInforChecked,
      };
    case 'language':
      return { ...state, lan: payload };
    default:
      return { ...state };
  }
};
