import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form, Input, Button, Modal } from 'antd';
import styles from './../../scss/modules/form.module.scss';
import { getCaptcha } from '../../api';

const SubmitModal = (props) => {
  const [form] = Form.useForm();
  const { visible, onFinish, onCancel } = props;
  const { t } = useTranslation();
  const [captchaId, setCaptchaId] = useState('');
  const [codeBase64Img, setCodeBase64Img] = useState('');

  useEffect(() => {
    if (visible) {
      form.resetFields();
      handleGetCaptcha();
    }
  }, [visible]);

  const handleGetCaptcha = async () => {
    try {
      const { id, data, mimeType } = await getCaptcha();
      setCaptchaId(id);
      setCodeBase64Img(`data:${mimeType};base64, ${data}`);
    } catch (err) {
      setCaptchaId('');
      setCodeBase64Img('');
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFinish = async ({ captchaValue }) => {
    onFinish({
      captchaId,
      captchaValue,
    });
  };

  return (
    <Modal
      title={t(`form.captchaValue`)}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          {t('button.cancel')}
        </Button>,
        <Button form="captcha" key="submit" type="primary" htmlType="submit">
          {t('button.save')}
        </Button>,
      ]}
    >
      <Form
        className={styles.captcha}
        form={form}
        id="captcha"
        onFinish={handleFinish}
      >
        <Form.Item
          style={{ width: '60%' }}
          name="captchaValue"
          label={t('form.captchaValue')}
          rules={[
            {
              required: true,
              message: t('form.required'),
            },
          ]}
        >
          <Input placeholder={t('form.input')} autoComplete="off" />
        </Form.Item>
        <div className={styles.code} onClick={handleGetCaptcha}>
          {codeBase64Img ? (
            <img
              style={{ display: 'block' }}
              src={codeBase64Img}
              alt="验证码"
            />
          ) : (
            '点击刷新'
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default withTranslation()(SubmitModal);
