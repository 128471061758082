import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Result, Button } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
import { AppContext } from '../common';

const NotFound = () => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: 'current', payload: 'notFound' });
  }, []);

  const handleBackHome = () => {
    return navigate('/');
  };

  return (
    <Result
      status={404}
      title={'404'}
      subTitle={t('error.notFound')}
      extra={
        <Button type="primary" onClick={handleBackHome}>
          {t('button.back')}
        </Button>
      }
    />
  );
};

export default withTranslation()(NotFound);
