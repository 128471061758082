import React, { useContext } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { message as Message, Button } from 'antd';
import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { confirmBasicInfo, getHomeInfo } from '../../api';
import { AppContext, FORMINFO, formatTemplate } from '../../common';

const ConfimActions = ({ isConfirmed, confirmType, refreshList }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);

  const getFormTemplate = async () => {
    dispatch({ type: 'loading', payload: true });
    try {
      const {
        model: { formList, importFormFields },
      } = await getHomeInfo();
      if (formList && formList.length) {
        const holder = formatTemplate(formList, 'form1');
        const drugs = formatTemplate(formList, 'form2');
        const overseas = importFormFields?.overseas || [];
        const territory = importFormFields?.territory || [];
        dispatch({ type: 'formTemplate', payload: { holder, drugs } });
        dispatch({ type: 'importFields', payload: { overseas, territory } });
      }
    } catch (err) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleConfirmBasicInfo = async (comfirm) => {
    dispatch({ type: 'loading', payload: true });
    try {
      await confirmBasicInfo({
        comfirm,
        formId: FORMINFO.DRUG,
      });

      if (comfirm) {
        Message.success(t('message.confirmSuccess'));
      } else {
        Message.success(t('message.cancelConfirmSuccess'));
      }
      dispatch({
        type: 'basicInforChecked',
        payload: { [confirmType]: comfirm },
      });
      await getFormTemplate();
      refreshList({});
    } catch (error) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  return (
    <>
      {!!isConfirmed && (
        <Button
          style={{ marginLeft: 20 }}
          type="primary"
          icon={<CloseSquareOutlined />}
          onClick={() => handleConfirmBasicInfo(false)}
        >
          {t('button.cancelConfirmation')}
        </Button>
      )}
      {!isConfirmed && (
        <Button
          style={{ marginLeft: 20 }}
          type="primary"
          icon={<CheckSquareOutlined />}
          onClick={() => handleConfirmBasicInfo(true)}
        >
          {t('button.confirmBasicInfo')}
        </Button>
      )}
    </>
  );
};

export default withTranslation()(ConfimActions);
