import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
const { Option } = Select;

const AddItemSelect = ({ items, value, onSelectChange }) => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [list, setList] = useState(items);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    setName('');
    setList([
      ...list,
      {
        value: name,
        label: name,
        enLabel: name,
      },
    ]);
  };

  return (
    <Select
      style={{
        width: '100px',
      }}
      dropdownMatchSelectWidth={false}
      placeholder={t('form.select')}
      value={value}
      onChange={onSelectChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            <Input
              placeholder={t('form.input')}
              value={name}
              onChange={onNameChange}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem} />
          </Space>
        </>
      )}
    >
      {list.map((item, index) => (
        <Option key={index} value={item.value}>
          {i18n.language === 'cn' ? item.label : item.enLabel}
        </Option>
      ))}
    </Select>
  );
};

export default withTranslation()(AddItemSelect);
