import request from './request';

export const getFileList = async (data) => {
  const response = await request({
    url: '/api/attachment/list',
    method: 'get',
    token: true,
    params: data,
  });

  return response.data?.content;
};

export const fileUpload = async (data) => {
  let fd = new FormData();
  Object.keys(data).forEach((key) => {
    fd.append(key, data[key]);
  });
  const response = await request({
    url: '/api/attachment/upload',
    method: 'post',
    data: fd,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    token: true,
  });

  return response.data?.content;
};

export const fileDownload = async (id, downloadType) => {
  const response = await request({
    url: '/api/attachment/download',
    method: 'get',
    token: true,
    params: { id, downloadType },
    responseType: 'arraybuffer',
  });

  return response.data;
};

export const fileDelete = async (id) => {
  const response = await request({
    url: '/api/attachment/delete',
    method: 'post',
    token: true,
    params: { id },
  });

  return response.data?.content;
};

export const fileUpdate = async (data) => {
  const response = await request({
    url: '/api/attachment/update',
    method: 'post',
    token: true,
    data,
  });

  return response.data?.content;
};

export const getCommentList = async ({ formId, dataId, inputAreaId }) => {
  const response = await request({
    url: '/api/comment/list',
    method: 'get',
    token: true,
    params: { formId, dataId, inputAreaId },
  });

  return response.data?.content;
};

export const saveComments = async (data) => {
  const response = await request({
    url: '/api/comment/save',
    method: 'post',
    token: true,
    data,
  });

  return response.data?.content;
};

export const getFiles = async (params) => {
  const response = await request({
    url: '/api/attachment/all',
    method: 'get',
    params,
    token: true,
  });

  return response.data.content;
};

export const fileRename = async (data) => {
  const response = await request({
    url: '/api/attachment/rename',
    method: 'post',
    token: true,
    data,
  });

  return response.data.content;
};

export const fileReplace = async (data) => {
  let fd = new FormData();
  Object.keys(data).forEach((key) => {
    fd.append(key, data[key]);
  });
  const response = await request({
    url: '/api/attachment/replace',
    method: 'post',
    data: fd,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    token: true,
  });

  return response.data?.content;
};
