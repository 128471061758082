import React, { useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { Button, Form, Upload, Select, Modal, Checkbox } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { IMPORT_TYPE } from '../../common';

function ImportForm({ visible, onCancel, importFields, onImport }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [checkboxList, setCheckboxList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    setIndeterminate(
      !!checkedList.length && checkedList.length < checkboxList.length
    );
    form.setFieldValue('fieldList', checkedList);
    form.validateFields(['fieldList']);
  }, [checkedList]);

  const handleAllChange = (e) => {
    const checked = e.target.checked;
    const activeList = checked
      ? checkboxList.map((item) => item.id)
      : checkboxList.filter((item) => !!item.required).map((item) => item.id);
    setCheckedList(activeList);
    setCheckAll(checked);
  };

  const handleItemChange = (list) => {
    setCheckedList(list);
    setCheckAll(!!list.length && list.length === checkboxList.length);
  };

  const handleSelectChange = (value) => {
    setCheckboxList(importFields[IMPORT_TYPE[+value]] || []);
    setCheckedList(
      (importFields[IMPORT_TYPE[+value]] || [])
        .filter((item) => !!item.required)
        .map((item) => item.id)
    );
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onImport({ ...values, file: values.fileList?.[0]?.originFileObj });
        handleCancel();
      })
      .catch((errorInfo) => {
        // console.log('errorInfo:', errorInfo);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setCheckboxList([]);
    setCheckedList([]);
    onCancel();
  };

  const setFileList = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      title={t(`button.drugsImport`)}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('button.save')}
      cancelText={t('button.cancel')}
    >
      <Form form={form} preserve={false}>
        <Form.Item
          label={t('importForm.import')}
          name="fileList"
          valuePropName="fileList"
          getValueFromEvent={setFileList}
          rules={[
            {
              required: true,
              message: t('form.required'),
            },
          ]}
        >
          <Upload
            accept=".xls, .xlsx"
            beforeUpload={() => false}
            customRequest={() => {}}
            maxCount={1}
          >
            <Button type="ghost" icon={<UploadOutlined />}>
              {t(`button.upload`)}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label={t('drugs.drugType')}
          name="importType"
          rules={[
            {
              required: true,
              message: t('form.required'),
            },
          ]}
        >
          <Select onChange={handleSelectChange} placeholder={t('form.select')}>
            <Select.Option value="0">{t('importForm.territory')}</Select.Option>
            <Select.Option value="1">{t('importForm.overseas')}</Select.Option>
          </Select>
        </Form.Item>
        {!!checkboxList.length && (
          <Form.Item
            label={t('importForm.selectImportFields')}
            labelCol={{ span: 24 }}
            name="fieldList"
            rules={[
              {
                required: true,
                message: t('form.required'),
              },
            ]}
          >
            <Form.Item style={{ width: '100%', marginLeft: 20 }}>
              <Checkbox
                indeterminate={indeterminate}
                onChange={handleAllChange}
                checked={checkAll}
              >
                {t('form.all')}
              </Checkbox>
              <Checkbox.Group
                style={{ width: '100%', paddingLeft: 20 }}
                value={checkedList}
                onChange={handleItemChange}
              >
                {!!checkboxList?.length &&
                  checkboxList.map((checkboxItem) => (
                    <Checkbox
                      style={{ width: '100%', marginLeft: 0 }}
                      value={checkboxItem.id}
                      key={checkboxItem.id}
                      disabled={!!checkboxItem.required}
                    >
                      {checkboxItem.label}
                    </Checkbox>
                  ))}
              </Checkbox.Group>
            </Form.Item>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

export default withTranslation()(ImportForm);
