import { useContext, useEffect, useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Radio, Avatar, Dropdown, Menu, Modal, Button } from 'antd';
import {
  UserOutlined,
  DownOutlined,
  ExportOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styles from './../../scss/modules/home.module.scss';
import { useQuery, AppContext, REPORT_YEARS_VISIABLE } from './../../common';

const AppHeader = ({
  name,
  roleId,
  current,
  years,
  currentYear,
  startNewYearDisabled,
  onLogout,
  onNewYear,
}) => {
  const { t, i18n } = useTranslation();
  const language = useQuery().get('language') || 'cn';
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const [yearsVisiable, setYearsVisiable] = useState(false);
  const location = useLocation();

  const onClick = () => {
    Modal.confirm({
      title: t('common.confirm'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.isLogout'),
      okText: t('common.yes'),
      cancelText: t('common.no'),
      onOk: onLogout,
    });
  };

  const handleNewYear = () => {
    Modal.confirm({
      title: t('common.confirm'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.isNewYear'),
      okText: t('common.yes'),
      cancelText: t('common.no'),
      onOk: onNewYear,
    });
  };

  const handleYearChange = (event) => {
    dispatch({ type: 'currentYear', payload: event.key * 1 });
  };

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
    dispatch({ type: 'language', payload: event.target.value });
    navigate({
      search: `language=${event.target.value}`,
    });
  };

  useEffect(() => {
    if (language) {
      dispatch({ type: 'language', payload: language });
      i18n.changeLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    setYearsVisiable(REPORT_YEARS_VISIABLE.includes(location.pathname));
  }, [location]);

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.info}>
        <div className={styles.title}>
          {current ? t(`menu.${current}`) : ''}
        </div>
      </div>
      <div className={styles.user}>
        {yearsVisiable && roleId === 1 && (
          <Button
            disabled={startNewYearDisabled}
            onClick={handleNewYear}
            htmlType="submit"
          >
            {t('button.newYear')}
          </Button>
        )}
        {yearsVisiable && (
          <Dropdown
            placement="bottom"
            trigger={['click']}
            className={styles.language}
            overlay={
              <Menu
                onClick={handleYearChange}
                items={years?.map((item) => ({ key: item, label: item }))}
                selectable={true}
                defaultSelectedKeys={[`${currentYear}`]}
              ></Menu>
            }
          >
            <Button>
              {currentYear}
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
        <Radio.Group
          className={styles.language}
          value={i18n.language}
          onChange={handleLanguageChange}
        >
          <Radio.Button value="cn">中文</Radio.Button>
          <Radio.Button value="en">English</Radio.Button>
        </Radio.Group>
        <Avatar icon={<UserOutlined />} />
        <Dropdown
          placement="bottom"
          overlay={
            <Menu
              onClick={onClick}
              items={[
                {
                  key: 'logout',
                  icon: <ExportOutlined />,
                  label: t('common.logout'),
                },
              ]}
            ></Menu>
          }
        >
          <span className={styles.name}>
            {name} <DownOutlined />
          </span>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

export default withTranslation()(AppHeader);
