import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Button, Tabs } from 'antd';
import { authLogin, authSSOLogin, getCaptcha } from '../api';
import Logo from './../images/logo.svg';
import styles from './../scss/modules/login.module.scss';
import { useQuery, AppContext } from './../common';
import { AppFooter } from './../components';

const Login = () => {
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [codeBase64Img, setCodeBase64Img] = useState('');
  const [captchaId, setCaptchaId] = useState('');
  const { Content } = Layout;
  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const code = useQuery().get('code') || '';

  useEffect(() => {
    if (state.isAuth) {
      navigate('/');
      return;
    } else if (code) {
      handleSSOLogin();
    } else {
      // handleGetCaptcha();
    }
  }, []);

  /**
   *
   * @param params - loginId, password and captchaValue
   */
  const handlePasswordLogin = async (params) => {
    setLoading(true);
    try {
      // const { model } = await authLogin({ ...params, captchaId });
      const { model } = await authLogin({ ...params });
      dispatch({
        type: 'login',
        payload: model,
      });
      navigate('/');
    } catch (err) {
    } finally {
      setLoading(false);
      // handleGetCaptcha();
    }
  };

  // SSO login
  const handleSSOLogin = async () => {
    setLoading(true);
    try {
      const { model } = await authSSOLogin({ code });
      dispatch({
        type: 'login',
        payload: model,
      });
      navigate('/');
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  // Redirect to SSO
  const redirectToSSO = () => {
    const uri = `https://fedlogin.jnj.com/as/authorization.oauth2?client_id=mahar&response_type=code&redirect_uri=${window.location.origin}/auth&scope=address email openid phone profile`;
    window.location.href = decodeURIComponent(uri);
  };

  // Get captcha
  const handleGetCaptcha = async () => {
    try {
      const { id, data, mimeType } = await getCaptcha();
      setCaptchaId(id);
      setCodeBase64Img(`data:${mimeType};base64, ${data}`);
    } catch (err) {
      setCaptchaId('');
      setCodeBase64Img('');
    }
  };

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <div className={styles.title}>
          <img className={styles.logo} src={Logo} alt="Gu Yu" />
        </div>
        {/* <Tabs defaultActiveKey={code ? '2' : ''}>
          <TabPane tab="" key="2"> */}
        <div className={styles.form}>
          <p className={styles.info}>
            Please click "Login" button redirect to J&J login page to login.
          </p>
          <Button
            type="primary"
            block
            size="large"
            onClick={redirectToSSO}
            loading={loading}
          >
            Login
          </Button>
        </div>
        {/* </TabPane>
        </Tabs> */}
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default Login;
