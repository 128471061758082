import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import { Form, Button, Table, message as Message, Space } from 'antd';
import styles from './../scss/modules/list.module.scss';
import { AppContext, FORMINFO, ROLE } from '../common';
import { AccountForm, ListActions } from './../components';
import { getAccountsList, createOrUpdateAccount, resetPassword } from '../api';

const Users = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchForm] = Form.useForm();
  const [accountForm] = Form.useForm();
  const [queryModel, setQueryModel] = useState({
    keyword: '',
    roleId: '',
    ifEnable: '',
    pageCount: 10,
    pageIndex: 1,
  });
  const [loading, setLoading] = useState(false);
  const [formList, setFormList] = useState({
    entityTotalCount: 0,
    entities: [],
  });
  const [modalProps, setModalProps] = useState({
    title: '',
    type: '',
    visible: false,
    values: null,
  });

  const columns = [
    {
      title: t('account.userName'),
      dataIndex: 'userName',
    },
    {
      title: t('account.account'),
      dataIndex: 'account',
    },
    {
      title: t('account.roleId'),
      dataIndex: 'roleId',
      render: (roleId) => {
        switch (roleId) {
          case ROLE.ADMIN:
            return t('account.admin');
          case ROLE.EDITOR:
            return t('account.editor');
          case ROLE.MANAGER:
            return t('account.manager');
          default:
            return '';
        }
      },
    },
    {
      title: t('account.ifEnable'),
      dataIndex: 'ifEnable',
      render: (ifEnable) =>
        ifEnable ? t('account.enable') : t('account.disable'),
    },
    {
      title: t('account.creator'),
      dataIndex: 'creator',
    },
    {
      title: t('account.action'),
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleEditAccount(record)}>
            {t('button.edit')}
          </Button>
          {/* <Button
            type="link"
            onClick={() => handleChangePassword(record.accountId)}
          >
            {t('button.resetPassword')}
          </Button> */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!state.isAuth) return;
    dispatch({ type: 'current', payload: 'users' });
    if (state.user.roleId === ROLE.ADMIN) {
      getUsersList();
    } else {
      return navigate('/404');
    }
  }, [queryModel, state.isAuth]);

  const getUsersList = async () => {
    setLoading(true);
    try {
      const { model } = await getAccountsList({
        pageIndex: queryModel.pageIndex,
        pageCount: queryModel.pageCount,
        'QueryModel.Keyword': queryModel.keyword,
        'QueryModel.RoleId': queryModel.roleId,
        'QueryModel.IfEnable': queryModel.ifEnable,
      });
      setFormList({ ...model });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (values) => {
    const { keyword, roleId, ifEnable } = values;
    setQueryModel({
      ...queryModel,
      keyword: keyword || '',
      roleId: roleId || '',
      ifEnable: ifEnable === undefined ? '' : ifEnable,
      pageIndex: 1,
    });
  };

  const handleReset = () => {
    searchForm.resetFields();
    setQueryModel({
      keyword: '',
      roleId: '',
      ifEnable: '',
      pageIndex: 1,
      pageCount: 10,
    });
  };

  const handleTableChange = (pagination) => {
    setQueryModel({
      ...queryModel,
      pageIndex: pagination.current,
      pageCount: pagination.pageSize,
    });
  };

  const handleEditAccount = (values) => {
    let { accountId, permissions } = values;
    const holderObj = permissions.find(
      (item) => item.formId === FORMINFO.HOLDER
    );
    const drugsObj = permissions.find((item) => item.formId === FORMINFO.DRUG);
    let holder = [];
    let drugs = [];
    if (holderObj && holderObj?.formItemId) {
      holder = holderObj.formItemId.split(',').map((item) => parseInt(item));
    }
    if (drugsObj && drugsObj?.formItemId) {
      drugs = drugsObj.formItemId.split(',').map((item) => parseInt(item));
    }
    setModalProps({
      visible: true,
      action: 'editAccount',
      userId: accountId,
      values: {
        ...values,
        holder,
        drugs,
      },
    });
  };

  const handleAddAccount = () => {
    setModalProps({
      visible: true,
      action: 'addAccount',
    });
  };

  const handleUpdateAccount = async (values) => {
    const { action, userId } = modalProps;
    if (action === 'resetPassword') {
      const { newPwd } = values;
      try {
        await resetPassword({
          userId,
          newPwd,
        });
        handleModalCancel();
        Message.success('操作成功');
      } catch (err) {}
    } else {
      const permissions = [];
      let {
        holder,
        drugs,
        account,
        email,
        ifEnable,
        remark,
        roleId,
        userName,
        password,
        responsiblePersonnel,
      } = values;

      if (holder) {
        permissions.push({
          formId: FORMINFO.HOLDER,
          formItemId: holder,
        });
      }
      if (drugs) {
        permissions.push({
          formId: FORMINFO.DRUG,
          formItemId: drugs,
        });
      }

      const data = {
        account,
        email,
        ifEnable,
        remark,
        roleId,
        userName,
        permissions,
        password,
        responsiblePersonnel,
      };

      if (action === 'editAccount') {
        data.userId = userId;
      }

      try {
        await createOrUpdateAccount(data);
        handleModalCancel();
        Message.success('操作成功');
        setQueryModel({
          ...queryModel,
          pageIndex: 1,
        });
      } catch (err) {}
    }
  };

  const handleChangePassword = (accountId) => {
    setModalProps({
      visible: true,
      action: 'resetPassword',
      userId: accountId,
    });
  };

  const handleModalCancel = () => {
    setModalProps({
      visible: false,
      userId: '',
      values: null,
    });
  };

  return (
    <div className="List">
      <div className={styles.wrapper}>
        <ListActions
          form={searchForm}
          loading={loading}
          filters={[
            {
              name: 'keyword',
              type: 'input',
              label: 'user',
            },
            {
              name: 'roleId',
              type: 'select',
              label: 'roleId',
              options: [
                {
                  label: t('account.admin'),
                  value: ROLE.ADMIN,
                },
                {
                  label: t('account.manager'),
                  value: ROLE.MANAGER,
                },
                {
                  label: t('account.editor'),
                  value: ROLE.EDITOR,
                },
              ],
            },
            {
              name: 'ifEnable',
              label: 'ifEnable',
              type: 'select',
              options: [
                {
                  label: t('form.enable'),
                  value: true,
                },
                {
                  label: t('form.disable'),
                  value: false,
                },
              ],
            },
          ]}
          type="account"
          onSearch={handleSearch}
          onReset={handleReset}
          onClickAdd={handleAddAccount}
        />
        <Table
          className={styles.table}
          loading={loading}
          columns={columns}
          rowKey={'accountId'}
          dataSource={formList.entities}
          pagination={{
            showTotal: (total) => t('message.tableTotalCount', { total }),
            pageSize: queryModel.pageCount,
            current: queryModel.pageIndex,
            total: formList.entityTotalCount,
            // hideOnSinglePage: true,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
        />
        <AccountForm
          form={accountForm}
          template={state.formTemplate}
          visible={modalProps.visible}
          values={modalProps.values}
          action={modalProps.action}
          onFinish={handleUpdateAccount}
          onCancel={handleModalCancel}
        />
      </div>
    </div>
  );
};

export default withTranslation()(Users);
