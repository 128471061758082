import { Form, Button, Collapse } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
import FormItem from './FormItem';
import { hasFieldRole, sortCompare } from './../../common';
import styles from './../../scss/modules/form.module.scss';
import { useState } from 'react';

const FormTemplate = (props) => {
  const { t, i18n } = useTranslation();
  const {
    form,
    isEdit,
    isTranslate,
    currentStep,
    title,
    subTitle,
    groups,
    fields,
    permissions,
    values,
    isUpload,
    fileList,
    comments,
    isAddComment,
    onAddComment,
    onShowComments,
    onValuesChange,
    onFinish,
    onFileUpload,
    onFileRemove,
    onFileDownload,
    onEditFileInfo,
    onNext,
    isGroupsCollapse,
    formDisabled,
  } = props;

  const [errorGroups, setErrorGroups] = useState([]);

  const checkHiddenStatus = (field, values) => {
    let isHidden = false;
    const { parentField, parentValue } = field;
    if (parentField) {
      if (/^\[.*\]$/.test(parentField)) {
        const parentFieldKeys = JSON.parse(parentField);
        const parentFields = fields.filter(
          (item) => parentFieldKeys.indexOf(item.dataField) > -1
        );
        let validVals = [];
        parentFields.forEach((item) => {
          const { dataField, type } = item;
          if (type === 'multiple-select') {
            validVals = [...validVals, ...(values?.[dataField] || [])];
          } else {
            validVals.push(values?.[dataField] || '');
          }
        });
        isHidden = validVals.indexOf(parentValue) === -1;
      } else {
        const parentFieldType = fields.find(
          (item) => item.dataField === parentField
        )?.type;

        if (parentFieldType && parentFieldType === 'multiple-select') {
          isHidden = values?.[parentField]?.indexOf(parentValue) === -1;
        } else {
          isHidden = values?.[parentField] !== parentValue;
        }
      }
    }

    return isHidden;
  };

  const groupFieldsRender = (groupFields) =>
    groupFields.sort(sortCompare('sort')).map((field) => {
      return (
        <FormItem
          {...field}
          key={field.id}
          disabled={
            field.disabled ||
            !hasFieldRole(field.id, permissions) ||
            formDisabled
          }
          readOnly={
            i18n.language === 'en'
              ? field.readOnly || !isTranslate
              : field.readOnly
          }
          isUpload={isUpload}
          isAddComment={isAddComment}
          isHidden={checkHiddenStatus(field, values)}
          fileList={
            field.type === 'upload'
              ? fileList.filter((file) => file.fieldId === field.id)
              : []
          }
          onFileUpload={onFileUpload}
          onFileRemove={onFileRemove}
          onFileDownload={onFileDownload}
          onEditFileInfo={onEditFileInfo}
          comments={comments.filter((comment) => comment.fieldId === field.id)}
          onAddComment={() => onAddComment(field.dataField, field.id)}
          onShowComments={() => onShowComments(field.dataField, field.id)}
        />
      );
    });

  const groupsRender = groups.map((item, index) => {
    const groupFields = fields.filter((field) => field.group === item.id);
    if (!groupFields.length) return null;

    const groupName =
      i18n.language === 'cn' ? item.groupName : item.enGroupName;
    const groupIsError = errorGroups.indexOf(index + 1) > -1;

    return !!isGroupsCollapse ? (
      <Collapse.Panel
        key={index}
        forceRender
        header={groupName}
        className={groupIsError && 'collapse-item-error'}
      >
        {groupFieldsRender(groupFields)}
      </Collapse.Panel>
    ) : (
      <div className={styles.group} key={index}>
        {item.groupName && <p style={{ width: '100%' }}>{groupName}</p>}
        {groupFieldsRender(groupFields)}
      </div>
    );
  });

  const handleFinishFailed = ({ errorFields }) => {
    const errors = [];
    errorFields.forEach((item) => {
      const [name] = item.name;
      const errorField = fields.find((field) => field.dataField === name);
      if (errors.indexOf(+errorField.group) === -1) {
        errors.push(+errorField.group);
      }
    });
    setErrorGroups(errors);
  };

  // Only save without validate form fields
  const handleOnlySave = () => {
    const values = form.getFieldsValue();
    onFinish(values, false);
  };
  return (
    <div className={styles.content}>
      <p className={styles.pageSubTitle}>{title}</p>
      {!!subTitle && <p className={styles.pageSubTitleSmall}>{subTitle}</p>}
      <Form
        form={form}
        size="large"
        colon={false}
        layout="vertical"
        scrollToFirstError={true}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        onFinishFailed={handleFinishFailed}
      >
        {!!isGroupsCollapse ? (
          <Collapse className={styles.collapse}>{groupsRender}</Collapse>
        ) : (
          groupsRender
        )}

        {!formDisabled && (
          <div className={styles.footer}>
            {i18n.language === 'cn' && (
              <Button size="large" type="primary" htmlType="submit">
                {t('button.submit')}
              </Button>
            )}
            {i18n.language === 'cn' && (
              <Button size="large" type="primary" onClick={handleOnlySave}>
                {t('button.save')}
              </Button>
            )}
            {i18n.language === 'en' && isTranslate && (
              <Button size="large" type="primary" htmlType="submit">
                {t('button.translate')}
              </Button>
            )}
            {currentStep !== -1 && (
              <Button
                size="large"
                style={{ marginLeft: '20px' }}
                onClick={() => onNext(currentStep + 1)}
              >
                {t('button.next')}
              </Button>
            )}
          </div>
        )}
      </Form>
    </div>
  );
};

export default withTranslation()(FormTemplate);
