import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, Modal, message as Message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import jsFileDownload from 'js-file-download';
import {
  FormSideRight,
  FormTemplate,
  TableList,
  DelegationForm,
  SubmitModal,
} from './../components';
import styles from './../scss/modules/form.module.scss';
import {
  getHolderDetail,
  saveHolder,
  getDrugInfo,
  fileUpload,
  getFileList,
  fileDelete,
  fileDownload,
  fileUpdate,
  getDelegationList,
  saveDelegationList,
  saveComments,
  getCommentList,
} from '../api';
import {
  formatFormValues,
  AppContext,
  STEPINFO,
  FORMINFO,
  LANGUAGE,
  ROLE,
  useQuery,
} from './../common';

const HolderForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const language = useQuery().get('language') || 'cn';
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const {
    state: {
      formTemplate: { holder },
      user: { permissions, roleId },
      years,
    },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [list, setList] = useState(null);
  const [stepStatus, setStepStatus] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [drugList, setDrugList] = useState([]);
  const [delegationListValues, setDelegationListValues] = useState({
    delegations: [],
    drugOptions: [],
    holderOptions: [],
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [comments, setComments] = useState([]);
  const [modalProps, setModalProps] = useState({
    type: '',
    visible: false,
    data: {},
  });
  const [drawerProps, setDrawerProps] = useState({
    visible: false,
    dataField: null,
    fieldId: null,
  });
  const [isSave, setIsSave] = useState({
    visible: false,
    next: -1,
    isSubmit: true,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [changedFormValues, setChangedFormValues] = useState(null);
  const [prevHolderType, setPrevHolderType] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);

  useEffect(() => {
    if (!holder) return;
    if (
      pathname === '/create/holder' &&
      permissions.holder.split(',').indexOf('2') === -1 &&
      permissions.holder.split(',').indexOf('18') === -1
    ) {
      navigate('/404');
    }
    dispatch({ type: 'current', payload: 'holderDetail' });
    setList(holder);
    return () => {
      setList(null);
      setFormValues({});
      setDrugList([]);
      setCurrentStep(0);
      setDelegationListValues({
        delegations: [],
        drugOptions: [],
        holderOptions: [],
      });
    };
  }, [id, pathname, holder]);

  useEffect(() => {
    if (!list) return;
    form.resetFields();
    if ([2, 3, 5].indexOf(currentStep) !== -1) {
      initFormValues();
      getFileListByStepId(list?.steps[currentStep].id);
    } else if (currentStep === 4) {
      initDelegationForm();
    } else {
      initFormValues();
    }
    getFieldsComment();
  }, [list, currentStep, language]);

  useEffect(() => {
    if (isSave.visible) {
      handleSaveForm({});
    }
  }, [isSave]);

  const initFormValues = async () => {
    if (pathname === '/create/holder') {
      let defaultValues = formatFormValues(list?.fields);
      form.setFieldsValue(defaultValues);
      setFormValues(defaultValues);
      setStepStatus(Array(list?.steps?.length).fill(STEPINFO.WAIT));
    } else if (id) {
      dispatch({ type: 'loading', payload: true });
      try {
        const { model } = await getHolderDetail({
          dataId: id,
          language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
        });
        let defaultValues = formatFormValues(list?.fields, model);
        setFormValues(defaultValues);
        form.setFieldsValue(defaultValues);
        setStepStatus(
          model.stepStatus.split(',').map((item) => parseInt(item))
        );
        const { model: drugsList } = await getDrugInfo({
          dataId: id,
          language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
        });
        setDrugList(drugsList);
        setPrevHolderType(model.holderType);
        setFormDisabled(model.year !== years[0]);
      } catch (error) {
        navigate('/404');
      } finally {
        dispatch({ type: 'loading', payload: false });
      }
    }
  };

  const initDelegationForm = async () => {
    if (id) {
      dispatch({ type: 'loading', payload: true });
      try {
        const { model } = await getDelegationList({
          dataId: id,
          language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
        });
        setDelegationListValues(model);
      } catch (error) {
      } finally {
        dispatch({ type: 'loading', payload: false });
      }
    }
  };

  const getFieldsComment = async () => {
    if (!id) return;
    dispatch({ type: 'loading', payload: true });
    try {
      const { model } = await getCommentList({
        formId: FORMINFO.HOLDER,
        dataId: id,
        inputAreaId: list?.steps[currentStep].id || 1,
      });
      setComments(model);
    } catch (error) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleFinish = async (values, isSubmit = true) => {
    if (isRequiredFieldIsNull()) return;

    setIsSave({
      visible: true,
      next: -1,
      isSubmit,
    });
    if (values) {
      setChangedFormValues(values);
    }
  };

  const isStepValid = (stepIndex) =>
    stepIndex <
    list?.steps.filter(
      (item) => !item.holderType || item.holderType === prevHolderType
    ).length;

  const handleStepChange = async (index) => {
    if (isEdit) {
      Modal.confirm({
        title: t('common.confirm'),
        icon: <ExclamationCircleOutlined />,
        content: t('common.isChange'),
        okText: t('common.yes'),
        cancelText: t('common.no'),
        onOk: async (close) => {
          try {
            // await form.validateFields();
            if (isRequiredFieldIsNull()) return;
            setIsSave({
              visible: true,
              next: index,
              isSubmit: false,
            });
          } finally {
            close();
          }
        },
        onCancel: () => {
          if (isStepValid(index)) {
            setCurrentStep(index);
            setIsEdit(false);
          }
        },
      });
    } else {
      setCurrentStep(index);
    }
  };

  const getFileListByStepId = async (stepId) => {
    if (!id) return;
    dispatch({ type: 'loading', payload: true });
    try {
      const { model } = await getFileList({
        formId: FORMINFO.HOLDER,
        dataId: id,
        inputAreaId: stepId,
      });
      setFileList(
        model.map((item) => {
          return {
            ...item,
            name: item.fileName,
            url: item.uri,
            status: 'done',
          };
        })
      );
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleFileUpload = async ({ data: { dataField, fieldId }, file }) => {
    dispatch({ type: 'loading', payload: true });
    try {
      await fileUpload({
        formId: FORMINFO.HOLDER,
        file,
        dataField,
        fieldId,
        dataId: id,
      });
      Message.success('操作成功');
      getFileListByStepId(list?.steps[currentStep].id);
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleFileRemove = async (file) => {
    Modal.confirm({
      title: t('common.confirm'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteFile'),
      okText: t('common.yes'),
      cancelText: t('common.no'),
      onOk: async () => {
        dispatch({ type: 'loading', payload: true });
        try {
          await fileDelete(file.id);
          Message.success('操作成功');
          getFileListByStepId(list?.steps[currentStep].id);
        } finally {
          dispatch({ type: 'loading', payload: false });
        }
      },
    });
  };

  const handleFileDownload = async (file) => {
    dispatch({ type: 'loading', payload: true });
    try {
      const result = await fileDownload(file.id);
      jsFileDownload(result, file.name);
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleEditFileInfo = ({ id, fileNumber, versionNumber }) => {
    setModalProps({
      type: 'file',
      visible: true,
      data: {
        fileId: id,
        fileNumber,
        versionNumber,
      },
    });
  };

  const handleValueChange = (changedValues) => {
    if (
      i18n.language === 'cn' ||
      (i18n.language === 'en' &&
        [ROLE.ADMIN, ROLE.MANAGER].indexOf(roleId) !== -1)
    ) {
      setIsEdit(true);
    }
    setFormValues({ ...formValues, ...changedValues });
    setChangedFormValues(changedValues);
  };

  const handleListValuesChange = (changedValues) => {
    if (
      i18n.language === 'cn' ||
      (i18n.language === 'en' &&
        [ROLE.ADMIN, ROLE.MANAGER].indexOf(roleId) !== -1)
    ) {
      setIsEdit(true);
    }
    setChangedFormValues(changedValues);
  };

  const isRequiredFieldIsNull = () => {
    if (!id && i18n.language === 'en') {
      return true;
    } else if (i18n.language === 'en') {
      return false;
    }

    const { holderType, holderName, cnHolderName } = formValues;
    if (!holderType) {
      Message.error(t('holder.holderType') + '为空');
      return true;
    } else if (holderType === 'Territory' && !holderName) {
      Message.error(t('drugs.holderName') + '为空');
      return true;
    } else if (holderType === 'Overseas' && !cnHolderName) {
      Message.error(t('holder.holderName') + '为空');
      return true;
    }

    return false;
  };

  const handleFinishList = async (valuesList, isSubmit = true) => {
    if (isRequiredFieldIsNull()) return;

    valuesList.map((item) => {
      item.holderId = id;
      return item;
    });

    setIsSave({
      visible: true,
      next: -1,
      isSubmit,
    });

    setChangedFormValues(valuesList);
  };

  const handleAddComment = (dataField, fieldId) => {
    setModalProps({
      type: 'comment',
      visible: true,
      data: {
        dataField,
        fieldId,
      },
    });
  };

  const handleShowComments = (dataField, fieldId) => {
    setDrawerProps({
      visible: true,
      dataField,
      fieldId,
    });
  };

  const handleModalSubmit = async (values) => {
    try {
      dispatch({ type: 'loading', payload: true });
      const { data, type } = modalProps;
      if (type === 'comment') {
        await saveComments({
          formId: FORMINFO.HOLDER,
          dataId: id,
          fieldId: data?.fieldId,
          dataField: data?.dataField,
          comment: values[data?.dataField],
        });
        getFieldsComment();
      } else {
        await fileUpdate({
          id: data?.fileId,
          ...values,
        });
        getFileListByStepId(list?.steps[currentStep].id);
      }

      Message.success('操作成功');
      setModalProps({
        type: '',
        visible: false,
        data: {},
      });
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleSaveForm = async (values) => {
    const next = isSave.next;
    const isSubmit = isSave.isSubmit;
    if (next !== -1) {
      Message.info('正在保存修改');
    }
    setIsSave({
      visible: false,
      next: -1,
      isSubmit: true,
    });
    dispatch({ type: 'loading', payload: true });
    try {
      if (currentStep !== 4) {
        const { model: formId } = await saveHolder(
          {
            ...changedFormValues,
            ...values,
            needVerify: true,
            id: id || 0,
          },
          {
            inputAreaId: list?.steps[currentStep].id,
            language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
            isSubmit,
          }
        );
        Message.success('操作成功');
        if (!id) {
          navigate(`/detail/holder/${formId}`);
          const { model } = await getHolderDetail({
            dataId: formId,
            language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
          });
          let defaultValues = formatFormValues(list?.fields, model);
          setFormValues(defaultValues);
          form.setFieldsValue(defaultValues);
          const { model: drugsList } = await getDrugInfo({
            dataId: formId,
            language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
          });
          setDrugList(drugsList);
        }
        initFormValues();
        window.scrollTo(0, 0);
        setIsEdit(false);
      } else {
        dispatch({ type: 'loading', payload: true });
        await saveDelegationList(
          {
            holderId: id,
            sysDelegations: changedFormValues,
            ...values,
          },
          {
            language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
            isSubmit,
          }
        );
        Message.success('操作成功');
        initDelegationForm();
        setIsEdit(false);
      }
      if (next !== -1) {
        setCurrentStep(next);
      }
    } catch (error) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleCancelSaveForm = () => {
    setIsSave({
      visible: false,
      next: -1,
      isSubmit: true,
    });
  };

  const title = `${currentStep + 1}. ${
    i18n.language === 'cn'
      ? list?.steps[currentStep].name
      : list?.steps[currentStep].enName
  }`;

  const getSubTitle = () => {
    if (!formValues.holderType) return '';
    const fieldKeyList = {
      Territory: ['holderName'],
      Overseas: ['cnHolderName', 'enHolderName', 'domesticAgentName'],
    };
    const subTitle = [];
    fieldKeyList[formValues.holderType].forEach((key) => {
      if (formValues[key]) {
        subTitle.push(formValues[key]);
      }
    });
    return !!subTitle.length ? subTitle.join(' - ') : '';
  };

  // Common form template props.
  const commonProps = {
    form,
    title,
    subTitle: getSubTitle(),
    isEdit,
    isTranslate: [ROLE.ADMIN, ROLE.MANAGER].indexOf(roleId) !== -1,
    permissions: permissions.holder,
    onNext: handleStepChange,
  };

  // Comment props.
  const commentProps = {
    isAddComment: !!id,
    comments,
    onAddComment: handleAddComment,
    onShowComments: handleShowComments,
  };

  // File props.
  const fileProps = {
    isUpload: !!id,
    fileList,
    onFileUpload: handleFileUpload,
    onFileRemove: handleFileRemove,
    onFileDownload: handleFileDownload,
    onEditFileInfo: handleEditFileInfo,
  };

  const formTemplateProps = {
    ...commonProps,
    ...commentProps,
    ...fileProps,
    groups: list?.groups.filter(({ stepIndex }) => stepIndex === currentStep),
    fields: list?.fields.filter(({ stepIndex }) => stepIndex === currentStep),
    isGroupsCollapse: currentStep === 3, // Index of Setp4 === 3
    currentStep:
      currentStep <
      list?.steps.filter(
        (item) => !item.holderType || item.holderType === formValues.holderType
      ).length -
        1
        ? currentStep
        : -1,
    values: formValues,
    onValuesChange: handleValueChange,
    onFinish: handleFinish,
  };

  const delegationFormProps = {
    ...commonProps,
    ...commentProps,
    currentStep,
    groupItem: list?.fields.find(({ stepIndex }) => stepIndex === currentStep),
    drugsList: drugList,
    delegationList: delegationListValues,
    onFinish: handleFinishList,
    onValuesChange: handleListValuesChange,
  };

  const tableListProps = {
    title,
    subTitle: getSubTitle(),
    data: drugList,
    currentStep,
    onNext: handleStepChange,
    onFinish: handleFinish,
  };

  return (
    <div className={`${styles.wrapper} form-section`}>
      {list && list?.steps.length > 0 && (
        <>
          {list?.steps[currentStep]?.type === 'Default' && (
            <FormTemplate {...formTemplateProps} formDisabled={formDisabled} />
          )}
          {list?.steps[currentStep]?.type === 'List' && (
            <DelegationForm
              {...delegationFormProps}
              formDisabled={formDisabled}
            />
          )}
          {list?.steps[currentStep]?.type === 'Table' && (
            <TableList {...tableListProps} formDisabled={formDisabled} />
          )}
          <FormSideRight
            current={currentStep}
            steps={list?.steps.filter(
              (item) =>
                !item.holderType || item.holderType === formValues.holderType
            )}
            status={stepStatus}
            modalConfig={{
              ...modalProps,
              onModalSubmit: handleModalSubmit,
              onCancel: () => {
                setModalProps({
                  file: '',
                  visible: false,
                  data: {},
                });
              },
            }}
            drawerConfig={{
              ...drawerProps,
              commentList: comments.filter(
                (item) =>
                  item.dataField === drawerProps.dataField &&
                  item.fieldId === drawerProps.fieldId
              ),
              onClose: () => {
                setDrawerProps({
                  visible: false,
                  dataField: null,
                  fieldId: null,
                });
              },
            }}
            onStepChange={handleStepChange}
          />
          {/* <SubmitModal
            visible={isSave.visible}
            onFinish={handleSaveForm}
            onCancel={handleCancelSaveForm}
          /> */}
        </>
      )}
    </div>
  );
};

export default withTranslation()(HolderForm);
