import { useState, useEffect, useContext } from 'react';
import { Form, Table, Tooltip, message as Message, Upload } from 'antd';
import { FilePdfOutlined, UploadOutlined } from '@ant-design/icons';
import { withTranslation, useTranslation } from 'react-i18next';
import jsFileDownload from 'js-file-download';
import styles from './../scss/modules/list.module.scss';
import { AppContext } from '../common';
import { getFiles, fileDownload, fileReplace, fileRename } from './../api';
import { ListActions, FileRenamePopover } from './../components';

const FilesList = () => {
  const { t, i18n } = useTranslation();
  const columns = [
    {
      title: t('files.holderName'),
      dataIndex: 'holderName',
      fixed: 'left',
      width: 150,
    },
    {
      title: t('files.authorizationNo'),
      dataIndex: 'authorizationNo',
      fixed: 'left',
      width: 150,
    },
    {
      title: t('files.chapter'),
      dataIndex: 'chapter',
      width: 150,
    },
    {
      title: t('files.fieldName'),
      dataIndex: 'fieldName',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
      width: 180,
    },
    {
      title: t('files.attachment'),
      dataIndex: 'attachment',
      width: 150,
      render: (item) => {
        const { fileExtension, fileName, id } = item;
        return (
          <>
            <span
              className={styles.filename}
              title={fileName}
              onClick={() => handleFileDownload(id, fileName)}
            >
              {fileName}
            </span>
            {['.doc', '.docx'].indexOf(fileExtension) !== -1 && (
              <span
                className={styles.fileicon}
                title={t('files.wordToPDF')}
                onClick={() =>
                  handleFileDownload(
                    id,
                    fileName.replace(fileExtension, '.pdf'),
                    'pdf'
                  )
                }
              >
                <FilePdfOutlined />
              </span>
            )}
          </>
        );
      },
    },
    {
      title: t('files.split'),
      width: 250,
      children: [
        {
          title: t('files.subWordAttachment'),
          dataIndex: 'subWordAttachment',
          render: (item) => {
            return (
              item && (
                <>
                  <span
                    className={styles.filename}
                    title={item?.fileName}
                    onClick={() => handleFileDownload(item?.id, item?.fileName)}
                  >
                    {item?.fileName}
                  </span>
                  <FileRenamePopover
                    {...item}
                    onConfirmCallback={handleRefleshList}
                  />
                  <span
                    className={styles.fileicon}
                    title={t('files.wordToPDF')}
                    onClick={() =>
                      handleFileDownload(
                        item?.id,
                        item?.fileName.replace(item?.fileExtension, '.pdf'),
                        'pdf'
                      )
                    }
                  >
                    <FilePdfOutlined />
                  </span>
                </>
              )
            );
          },
          width: 125,
        },
        {
          title: t('files.subPdfAttachment'),
          dataIndex: 'subPdfAttachment',
          render: (item) => {
            return (
              item.length > 0 &&
              item.map((file) => (
                <div key={file?.id}>
                  <span
                    className={styles.filename}
                    title={file?.fileName}
                    onClick={() => handleFileDownload(file?.id, file?.fileName)}
                  >
                    {file?.fileName}
                  </span>
                  <FileRenamePopover
                    {...file}
                    onConfirmCallback={handleRefleshList}
                  />
                  <Upload
                    accept=".pdf"
                    data={{ id: file?.id }}
                    showUploadList={false}
                    beforeUpload={handleBeforeReplace}
                    customRequest={handleFileReplace}
                  >
                    <span
                      className={styles.fileicon}
                      title={t('files.replace')}
                    >
                      <UploadOutlined />
                    </span>
                  </Upload>
                </div>
              ))
            );
          },
          width: 125,
        },
      ],
    },
  ];

  const { state, dispatch } = useContext(AppContext);
  const [form] = Form.useForm();
  const [queryModel, setQueryModel] = useState({
    pageCount: 15,
    pageIndex: 1,
    authorizationNo: '',
    chapter: '',
    holderName: '',
  });
  const [loading, setLoading] = useState(false);
  const [formList, setFormList] = useState({
    entityTotalCount: 0,
    entities: [],
  });

  useEffect(() => {
    if (state.isAuth) {
      dispatch({ type: 'current', payload: 'files' });
      getDataList();
    } else {
      setLoading(false);
    }
  }, [queryModel, state.isAuth, state.currentYear]);

  const getDataList = async () => {
    try {
      setLoading(true);
      const { model } = await getFiles({
        pageIndex: queryModel.pageIndex,
        pageCount: queryModel.pageCount,
        'QueryModel.AuthorizationNo': queryModel.authorizationNo,
        'QueryModel.HolderName': queryModel.holderName,
        'QueryModel.Chapter': queryModel.chapter,
        'SortModel.SortBy': 'updateTime',
        'SortModel.AscOrDesc': '',
        year: state.currentYear,
      });
      model.entities.map((item, index) => {
        item.key = index;
        return item;
      });
      setFormList({ ...model });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = ({ authorizationNo, chapter, holderName }) => {
    setQueryModel({
      ...queryModel,
      pageIndex: 1,
      authorizationNo,
      holderName,
      chapter,
    });
  };

  const handleReset = () => {
    form.resetFields();
    setQueryModel({
      ...queryModel,
      pageIndex: 1,
      authorizationNo: '',
      chapter: '',
      holderName: '',
    });
  };

  const handleTableChange = (pagination) => {
    setQueryModel({
      ...queryModel,
      pageIndex: pagination.current,
      pageCount: pagination.pageSize,
    });
  };

  const handleFileDownload = async (id, name, type) => {
    setLoading(true);
    try {
      const result = await fileDownload(id, type);
      jsFileDownload(result, name);
    } finally {
      setLoading(false);
    }
  };

  const handleFileReplace = async ({ data: { id }, file }) => {
    setLoading(true);
    try {
      await fileReplace({
        file,
        id,
      });
      Message.success('操作成功');
      setQueryModel({
        ...queryModel,
        pageIndex: 1,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBeforeReplace = (file) => {
    if (file.size > 10 * 1024 * 1024) {
      Message.error('文件大小不能超过10MB，请重新上传');
      return false;
    }
    return true;
  };

  const handleRefleshList = () => {
    setQueryModel({
      ...queryModel,
      pageIndex: 1,
    });
  };

  return (
    <div className="List">
      <div className={styles.wrapper}>
        <ListActions
          form={form}
          loading={loading}
          filters={[
            {
              name: 'authorizationNo',
              type: 'input',
              label: 'authorizationNo',
            },
            {
              name: 'holderName',
              type: 'input',
              label: 'holderName',
            },
            {
              name: 'chapter',
              type: 'select',
              label: 'chapter',
              options: [
                {
                  label: t('form.drugs'),
                  options: [
                    ...state.formTemplate?.drugs?.steps?.map((item) => {
                      return {
                        ...item,
                        label: i18n.language === 'cn' ? item.name : item.enName,
                        value: item.id,
                      };
                    }),
                  ],
                },
                {
                  label: t('form.holder'),
                  options: [
                    ...state.formTemplate?.holder?.steps?.map((item) => {
                      return {
                        ...item,
                        label: i18n.language === 'cn' ? item.name : item.enName,
                        value: item.id,
                      };
                    }),
                  ],
                },
              ],
            },
          ]}
          onSearch={handleSearch}
          onReset={handleReset}
        />
        <Table
          scrollToFirstRowOnChange
          className={styles.table}
          scroll={{ x: 1000, y: 680 }}
          loading={loading}
          columns={columns}
          dataSource={formList.entities}
          pagination={{
            showTotal: (total) => t('message.tableTotalCount', { total }),
            pageSizeOptions: [15, 30, 50, 100],
            pageSize: queryModel.pageCount,
            current: queryModel.pageIndex,
            total: formList.entityTotalCount,
            // hideOnSinglePage: true,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default withTranslation()(FilesList);
