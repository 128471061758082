export const STATUS = {
  TOTAL: [1, 2],
  PROCESS: [1],
  FINISHED: [2],
};

export const FORMINFO = {
  HOLDER: 1,
  DRUG: 2,
};

export const LANGUAGE = {
  CN: 1,
  EN: 2,
};

export const USER = {
  id: 0,
  name: null,
  roleId: 0,
  permissions: {
    holder: '',
    drugs: '',
  },
  isResponsiblePersonnel: false,
};

export const BASIC_INFO_CHECKED = {
  drug: false,
  holder: false,
};

export const TEMPLATE = {
  holder: null,
  drugs: null,
};

export const IMPORT_FIELDS = {
  territory: null,
  overseas: null,
};

export const IMPORT_TYPE = ['territory', 'overseas'];

export const ROLE = {
  ADMIN: 1,
  EDITOR: 2,
  MANAGER: 3,
};

export const STEPINFO = {
  WAIT: 0,
  FINISHED: 1,
  STATUS: {
    0: 'wait',
    1: 'finish',
  },
};

export const REPORT_YEARS_VISIABLE = ['/drugs', '/holder', '/files'];
