import { useEffect, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Layout, Spin, BackTop } from 'antd';
import { AppHeader, SideMenu, AppFooter } from './../components';
import { authLogout, getHomeInfo, getYears } from './../api';
import styles from './../scss/modules/home.module.scss';
import { AppContext, formatTemplate } from '../common';

const Home = ({ t }) => {
  const { state, dispatch } = useContext(AppContext);
  const { Content } = Layout;
  const navigate = useNavigate();

  const getFormTemplate = async () => {
    dispatch({ type: 'loading', payload: true });
    try {
      const {
        model: { formList, importFormFields, years, startNewYearDisabled },
      } = await getHomeInfo();
      if (formList && formList.length && years?.length) {
        const holder = formatTemplate(formList, 'form1');
        const drugs = formatTemplate(formList, 'form2');
        const overseas = importFormFields?.overseas || [];
        const territory = importFormFields?.territory || [];
        dispatch({ type: 'formTemplate', payload: { holder, drugs } });
        dispatch({ type: 'importFields', payload: { overseas, territory } });
        dispatch({ type: 'years', payload: years });
        dispatch({ type: 'currentYear', payload: years[0] });
        dispatch({
          type: 'startNewYearDisabled',
          payload: !!startNewYearDisabled,
        });
      }
    } catch (err) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  // Lougout
  const handleLogout = async () => {
    try {
      await authLogout();
    } catch (err) {
    } finally {
      dispatch({ type: 'logout' });
    }
  };

  // start new year
  const handleNewYear = async () => {
    dispatch({ type: 'loading', payload: true });
    try {
      await getYears();
      await getFormTemplate();
    } catch (err) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  // Get user info and form template
  useEffect(() => {
    if (!state.isAuth || !state.user.id) return navigate('auth');
    if (
      !state.formTemplate.holder ||
      !state.formTemplate.drugs ||
      state.years.length === 0
    ) {
      getFormTemplate();
    }
  }, []);

  return (
    <div className="home">
      <BackTop style={{ zIndex: 999, right: '24px', bottom: '60px' }} />
      <Spin spinning={state.loading} tip={t('common.loading')}>
        <Layout className={styles.layout}>
          <SideMenu activeKey={state.currentPage} user={state.user} />
          <Layout className={styles.wrapper}>
            <AppHeader
              name={state.user.name}
              roleId={state.user.roleId}
              current={state.currentPage}
              years={state.years}
              currentYear={state.currentYear}
              startNewYearDisabled={state.startNewYearDisabled}
              onLogout={handleLogout}
              onNewYear={handleNewYear}
            />
            <Content>{state.isAuth ? <Outlet /> : null}</Content>
            <AppFooter />
          </Layout>
        </Layout>
      </Spin>
    </div>
  );
};

export default withTranslation()(Home);
