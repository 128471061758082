import { Steps, Modal, Form, Timeline, Input, Drawer } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
import styles from './../../scss/modules/card.module.scss';
import { STEPINFO } from './../../common';
import { useEffect, useState } from 'react';

const FormSideRight = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const { current, steps, status, modalConfig, drawerConfig, onStepChange } =
    props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalConfig.visible && modalConfig.type === 'file') {
      form.setFieldsValue({
        fileNumber: modalConfig.data?.fileNumber,
        versionNumber: modalConfig.data?.versionNumber,
      });
    } else if (!modalConfig.visible) {
      form.resetFields();
    }
  }, [modalConfig]);

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      await modalConfig.onModalSubmit(values);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    modalConfig.onCancel();
  };

  return (
    <>
      <div className={styles.sider_right}>
        <Steps
          current={current}
          size="small"
          direction="vertical"
          onChange={onStepChange}
        >
          {steps.map((item, index) => (
            <Steps.Step
              key={item.name}
              title={i18n.language === 'cn' ? item.name : item.enName}
              description={item.description}
              status={
                current === index ? 'process' : STEPINFO.STATUS[status[index]]
              }
            />
          ))}
        </Steps>
      </div>
      <Modal
        title={
          modalConfig.type === 'file'
            ? t('form.fileInfo')
            : t('form.addComment')
        }
        confirmLoading={loading}
        visible={modalConfig.visible}
        okText={t('button.save')}
        cancelText={t('button.cancel')}
        onCancel={onCancel}
        onOk={onSubmit}
      >
        <Form form={form}>
          {modalConfig.type === 'comment' && (
            <Form.Item
              name={modalConfig.data?.dataField}
              className={styles.item}
              rules={[{ required: true, message: '' }]}
            >
              <Input.TextArea placeholder={t('form.input')} />
            </Form.Item>
          )}
          {modalConfig.type === 'file' && (
            <>
              <Form.Item
                name="fileNumber"
                label={t('form.fileNumber')}
                className={styles.item}
              >
                <Input placeholder={t('form.input')} />
              </Form.Item>
              <Form.Item
                name="versionNumber"
                label={t('form.versionNumber')}
                className={styles.item}
              >
                <Input placeholder={t('form.input')} />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
      <Drawer
        title={t('common.commentList')}
        placement="right"
        width={378}
        closable={true}
        onClose={drawerConfig.onClose}
        visible={drawerConfig.visible}
      >
        <Timeline>
          {drawerConfig.dataField &&
            drawerConfig.commentList.map((item, index) => (
              <Timeline.Item key={index}>
                {item.comment}
                <br />- {item.createUserName}, {item.createTime}
              </Timeline.Item>
            ))}
        </Timeline>
      </Drawer>
    </>
  );
};

export default withTranslation()(FormSideRight);
