import { useReducer, useMemo } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import { withTranslation } from 'react-i18next';
import { mainReducer, AppContext, initState } from './common';
import {
  Login,
  UserLogin,
  Home,
  HolderList,
  DrugsList,
  HolderForm,
  DrugForm,
  NotFound,
  Logs,
  Users,
  FilesList,
} from './pages';

const App = () => {
  const [state, dispatch] = useReducer(mainReducer, initState);
  const appStateValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const locale = useMemo(() => state.lan, [state.lan]);

  return (
    <ConfigProvider locale={locale === 'cn' ? zhCN : enUS}>
      <AppContext.Provider value={appStateValue}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route index element={<Navigate replace to="drugs" />} />
              <Route path="holder" element={<HolderList />} />
              <Route path="drugs" element={<DrugsList />} />
              <Route path="logs" element={<Logs />} />
              <Route path="users" element={<Users />} />
              <Route path="files" element={<FilesList />} />
              <Route path="create/holder" element={<HolderForm />} />
              <Route path="detail/holder/:id" element={<HolderForm />} />
              <Route path="create/drug" element={<DrugForm />} />
              <Route path="detail/drug/:id" element={<DrugForm />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/auth" element={<Login />} />
            <Route path="/user-auth" element={<UserLogin />} />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </ConfigProvider>
  );
};

export default withTranslation()(App);
