import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { message as Message, Popconfirm, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { fileRename } from '../../api';
import styles from './../../scss/modules/list.module.scss';

const FileRenamePopover = ({
  id,
  fileExtension,
  fileName,
  fileNumber,
  versionNumber,
  onConfirmCallback,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [value, setValue] = useState('');

  const handleValueChange = (e) => {
    const name = e.target.value;
    setValue(name);
    setDisabled(!name);
  };

  const handleVisibleChange = (visible) => {
    if (visible) {
      setValue(fileName.replace(fileExtension, ''));
      setDisabled(false);
    }
    setVisible(visible);
  };

  const handleConfirm = async () => {
    try {
      await fileRename({
        id,
        fileNumber,
        versionNumber,
        fileName: `${value}${fileExtension}`,
      });
      Message.success('操作成功');
      onConfirmCallback();
    } finally {
    }
  };

  return (
    <>
      <Popconfirm
        icon={<></>}
        visible={visible}
        title={
          <Input
            allowClear
            suffix={fileExtension}
            value={value}
            onChange={handleValueChange}
          />
        }
        okButtonProps={{ disabled: disabled }}
        onVisibleChange={handleVisibleChange}
        onConfirm={handleConfirm}
      >
        <span className={styles.fileicon} title={t('files.rename')}>
          <EditOutlined />
        </span>
      </Popconfirm>
    </>
  );
};

export default withTranslation()(FileRenamePopover);
