import request from './request';

/**
 * get drugs list
 * @param {string} status
 * @returns - list
 */
export const getDrugsList = async (params) => {
  const response = await request({
    url: '/api/drugform/list',
    method: 'get',
    params,
    token: true,
  });

  return response.data.content;
};

/**
 * delete drugs list
 * @param {string[]} dataIds
 * @returns - true or false
 */
export const deleteDrugsList = async (dataIds) => {
  const response = await request({
    url: '/api/druglist/delete',
    method: 'post',
    data: {
      dataIds,
    },
    token: true,
  });

  return response.data?.content;
};

/**
 * update holder name
 * @param {*} data
 * @returns - true or false
 */
export const updateHolderNames = async (data) => {
  const response = await request({
    url: '/api/druglist/holderupdate',
    method: 'post',
    data,
    token: true,
  });

  return response.data?.content;
};

/**
 * import drug
 * @param {*} data
 * @returns - true or false
 */
export const importDrug = async (importData = {}) => {
  let data = new FormData();
  Object.keys(importData).forEach((item) => {
    data.append(item, importData[item]);
  });
  const response = await request({
    url: '/api/report/import',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    token: true,
  });

  return response.data?.content;
};

/**
 * get holder names
 * @returns - true or false
 */
export const getHolderNames = async (params) => {
  const response = await request({
    url: '/api/druglist/holderlist',
    method: 'get',
    params,
    token: true,
  });

  return response.data?.content;
};

/**
 * get drug detail
 * @param {*} id
 * @returns - form
 */
export const getDrugDetail = async (params) => {
  const response = await request({
    url: '/api/drugform/get',
    method: 'get',
    token: true,
    params,
  });

  return response.data?.content;
};

/**
 * save drug
 * @param {*} data
 * @returns - form
 */
export const saveDrug = async (data, params) => {
  const response = await request({
    url: '/api/drugform/save',
    method: 'post',
    token: true,
    data,
    params,
  });

  return response.data?.content;
};

/**
 * export drug list
 * @param {*} data
 * @returns - true or false
 */
export const exportDrugList = async (data) => {
  const response = await request({
    url: '/api/report/exportdrug',
    method: 'post',
    data,
    token: true,
    responseType: 'arraybuffer',
  });

  return response;
};

/**
 * export drug table list - TPL is same as Import
 * @param {*} params
 * @returns - true or false
 */
export const exportDrugTableList = async (params) => {
  const response = await request({
    url: '/api/report/exportdruginfo',
    method: 'get',
    params,
    token: true,
    responseType: 'arraybuffer',
  });

  return response;
};

/**
 * download drug templates
 * @returns - true or false
 */
export const downloadDrugTpl = async () => {
  const response = await request({
    url: '/api/report/templatedownload',
    method: 'get',
    token: true,
    responseType: 'arraybuffer',
  });

  return response;
};

/**
 * export chapter status holder & drug list
 * @param {*} params
 * @returns - true or false
 */
export const exportChapterStatus = async (params) => {
  const response = await request({
    url: '/api/report/exportChapterStatus',
    method: 'get',
    token: true,
    responseType: 'arraybuffer',
    params,
  });

  return response;
};

export const getProductionSalesList = async (params) => {
  const response = await request({
    url: '/api/productionsales/list',
    method: 'get',
    token: true,
    params,
  });

  return response.data?.content;
};

export const saveProductionSales = async (data, params) => {
  const response = await request({
    url: '/api/productionsales/save',
    method: 'post',
    token: true,
    data,
    params,
  });

  return response.data?.content;
};

export const getChangemanagementsList = async (params) => {
  const response = await request({
    url: '/api/changemanagements/list',
    method: 'get',
    token: true,
    params,
  });

  return response.data?.content;
};

export const saveChangemanagements = async (data, params) => {
  const response = await request({
    url: '/api/changemanagements/save',
    method: 'post',
    token: true,
    data,
    params,
  });

  return response.data?.content;
};

export const getProductionsales = async (params) => {
  const response = await request({
    url: '/api/productionsales/list',
    method: 'get',
    token: true,
    params,
  });

  return response.data?.content;
};

export const saveProductionsales = async (data, params) => {
  const response = await request({
    url: '/api/productionsales/save',
    method: 'post',
    token: true,
    data,
    params,
  });

  return response.data?.content;
};
