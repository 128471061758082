import { createContext } from 'react';
import {
  getLocalAccessToken,
  getLocalUserInfo,
  getLocalFormTemplate,
  getLocalImportFields,
  getLocalBasicInforConfirmed,
  getLocalYears,
  getLocalStartNewYearDisabled,
} from './util';

const user = getLocalUserInfo();
const isAuth = getLocalAccessToken();
const formTemplate = getLocalFormTemplate();
const importFields = getLocalImportFields();
const basicInforChecked = getLocalBasicInforConfirmed();
const years = getLocalYears();
const currentYear = years?.[0] || '';
const startNewYearDisabled = getLocalStartNewYearDisabled();

export const initState = {
  loading: false,
  user,
  isAuth: !!isAuth,
  formTemplate,
  importFields,
  currentPage: '',
  lan: 'cn',
  basicInforChecked,
  years,
  currentYear,
  startNewYearDisabled,
};

export const AppContext = createContext();
