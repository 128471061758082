import { withTranslation, useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import styles from './../../scss/modules/home.module.scss';

const AppFooter = () => {
  const { t } = useTranslation();
  return (
    <Layout.Footer className={styles.footer}>
      {t('common.footer')}
    </Layout.Footer>
  );
};

export default withTranslation()(AppFooter);
