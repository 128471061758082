import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form, Select, Input, Switch, Button, Modal, Checkbox } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styles from './../../scss/modules/list.module.scss';
import { ROLE } from './../../common';

const pwdPattern = new RegExp(
  '(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{10,20}'
);
const emailPattern = new RegExp(
  '^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$'
);

const AccountForm = (props) => {
  const [form] = Form.useForm();
  const { visible, action, template, values, onFinish, onCancel } = props;
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState('');
  const [petmissionFields, setPermissionFields] = useState({
    holder: [],
    drugs: [],
  });
  const [readOnly, setReadOnly] = useState(true);

  const formType = action === 'resetPassword' ? 'password' : 'account';

  useEffect(() => {
    if (formType === 'account' && visible) {
      if (values) {
        form.setFieldsValue(values);
      }
      const { holder, drugs } = template;
      if (holder && drugs) {
        setPermissionFields({
          holder: holder.steps.map((item, index) => {
            const plainOptions = holder.fields
              .filter((field) => field.stepIndex === index)
              .map((field) => field.id);
            let fields = [];
            if (values) {
              fields = plainOptions.filter(
                (id) => values.holder.indexOf(id) !== -1
              );
            }
            return {
              ...item,
              indeterminate:
                !!fields.length && fields.length < plainOptions.length,
              checkAll: plainOptions.length === fields.length,
              fields,
            };
          }),
          drugs: drugs.steps.map((item, index) => {
            const plainOptions = drugs.fields
              .filter((field) => field.stepIndex === index)
              .map((field) => field.id);
            let fields = [];
            if (values) {
              fields = plainOptions.filter(
                (id) => values.drugs.indexOf(id) !== -1
              );
            }
            return {
              ...item,
              indeterminate:
                !!fields.length && fields.length < plainOptions.length,
              checkAll: plainOptions.length === fields.length,
              fields,
            };
          }),
        });
      }
    }
    return () => {
      setPermissionFields({
        holder: [],
        drugs: [],
      });
    };
  }, [visible]);

  const setRules = (item) => {
    const { required, pattern, dependencies, messageKey } = item;
    let rules = [];
    if (required) {
      rules.push({
        required,
        message: t('form.required'),
      });
    }
    if (pattern && messageKey) {
      rules.push({
        pattern,
        message: t(`form.${messageKey}`),
      });
    }
    if (dependencies) {
      const [key] = dependencies;
      const errorMessage = t(`form.${messageKey}`);
      rules.push(({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue(key) === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(errorMessage));
        },
      }));
    }
    return rules.length ? rules : undefined;
  };

  const formItems = {
    account: [
      {
        name: 'userName',
        type: 'input',
        required: true,
      },
      {
        name: 'account',
        type: 'input',
        required: true,
      },
      // {
      //   name: 'password',
      //   type: 'password',
      //   required: true,
      //   pattern: pwdPattern,
      //   messageKey: 'password',
      // },
      // {
      //   name: 'email',
      //   type: 'input',
      //   required: true,
      //   pattern: emailPattern,
      //   messageKey: 'validation',
      // },
      {
        name: 'roleId',
        type: 'select',
        options: [
          {
            label: t('account.admin'),
            value: ROLE.ADMIN,
          },
          {
            label: t('account.manager'),
            value: ROLE.MANAGER,
          },
          {
            label: t('account.editor'),
            value: ROLE.EDITOR,
          },
        ],
      },
      {
        name: 'holder',
        type: 'popup',
      },
      {
        name: 'drugs',
        type: 'popup',
      },
      {
        name: 'ifEnable',
        type: 'switch',
        defaultChecked: true,
        options: [
          {
            label: t('account.enable'),
            value: 1,
          },
          {
            label: t('account.disable'),
            value: 0,
          },
        ],
      },
      {
        name: 'responsiblePersonnel',
        type: 'switch',
        defaultChecked: false,
        options: [
          {
            label: 'is responsiblePersonnel',
            value: 1,
          },
          {
            label: 'not a responsiblePersonnel',
            value: 0,
          },
        ],
      },
      {
        name: 'remark',
        type: 'input',
      },
    ],
    password: [
      {
        name: 'newPwd',
        type: 'password',
        required: true,
        pattern: pwdPattern,
        messageKey: 'password',
      },
      {
        name: 'confirmPwd',
        type: 'password',
        dependencies: ['newPwd'],
        required: true,
        messageKey: 'confirmPassword',
      },
    ],
  };

  const items = formItems[formType].map((item) => {
    const { name, type, options, defaultChecked } = item;
    const rules = setRules(item);
    if (type === 'input') {
      return (
        <Form.Item
          key={`${formType}_${name}`}
          name={name}
          rules={rules}
          label={t(`account.${name}`)}
          className={styles.item}
        >
          <Input
            disabled={action === 'editAccount' && name === 'account'}
            placeholder={t('form.input')}
            autoComplete="off"
          />
        </Form.Item>
      );
    } else if (type === 'password' && action !== 'editAccount') {
      return (
        <Form.Item
          key={name}
          name={name}
          rules={rules}
          label={t(`account.${name}`)}
          className={styles.item}
        >
          <Input.Password
            placeholder={t('form.input')}
            autoComplete="off"
            readOnly={readOnly}
            onFocus={() => {
              setReadOnly(false);
            }}
            onBlur={() => {
              setReadOnly(true);
            }}
          />
        </Form.Item>
      );
    } else if (type === 'select') {
      return (
        <Form.Item
          key={name}
          name={name}
          rules={rules}
          label={t(`account.${name}`)}
          className={styles.item}
        >
          <Select placeholder={t('form.select')} options={options} />
        </Form.Item>
      );
    } else if (type === 'popup') {
      return (
        <Form.Item
          key={name}
          name={name}
          rules={rules}
          label={t(`account.${name}`)}
          className={styles.item}
        >
          <Button
            type="text"
            htmlType="button"
            icon={<EditOutlined />}
            onClick={() => {
              setIsModalOpen(name);
            }}
          />
        </Form.Item>
      );
    } else if (type === 'switch') {
      return (
        <Form.Item
          key={name}
          name={name}
          label={t(`account.${name}`)}
          className={styles.item}
          valuePropName="checked"
        >
          <Switch
            checkedChildren={t('account.enable')}
            unCheckedChildren={t('account.disable')}
            defaultChecked={defaultChecked}
          />
        </Form.Item>
      );
    } else {
      return null;
    }
  });

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFinish = async (values) => {
    setIsModalOpen('');
    let holder = [];
    let drugs = [];
    petmissionFields.holder.forEach((item) => {
      if (item.fields.length) {
        holder = holder.concat(item.fields);
      }
    });
    petmissionFields.drugs.forEach((item) => {
      if (item.fields.length) {
        drugs = drugs.concat(item.fields);
      }
    });
    onFinish({
      ...values,
      holder: holder.join(),
      drugs: drugs.join(),
      ifEnable: values.ifEnable === undefined ? true : values.ifEnable,
      remark: values.ifEnable === undefined ? '' : values.remark,
      responsiblePersonnel: !!values.responsiblePersonnel,
    });
  };

  const onCheckAllChange = (e, index) => {
    const plainOptions = template[isModalOpen].fields
      .filter((field) => field.stepIndex === index)
      .map((field) => field.id);
    const item = petmissionFields[isModalOpen][index];
    item.fields = e.target.checked ? plainOptions : [];
    item.indeterminate = false;
    item.checkAll = e.target.checked;
    setPermissionFields({
      ...petmissionFields,
      [isModalOpen]: petmissionFields[isModalOpen].map((step, i) => {
        if (i === index) return { ...step, item };
        return step;
      }),
    });
  };

  const onChangeFieds = (values, index) => {
    const plainOptions = template[isModalOpen].fields
      .filter((field) => field.stepIndex === index)
      .map((field) => field.id);
    let { fields, indeterminate, checkAll } =
      petmissionFields[isModalOpen][index];
    fields = values;
    indeterminate = !!fields.length && fields.length < plainOptions.length;
    checkAll = fields.length === plainOptions.length;
    setPermissionFields({
      ...petmissionFields,
      [isModalOpen]: petmissionFields[isModalOpen].map((step, i) => {
        if (i === index) return { ...step, fields, indeterminate, checkAll };
        return step;
      }),
    });
  };

  return (
    <>
      <Modal
        title={t(`account.${action}`)}
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t('button.cancel')}
          </Button>,
          <Button form="account" key="submit" type="primary" htmlType="submit">
            {t('button.save')}
          </Button>,
        ]}
      >
        <Form form={form} id="account" onFinish={handleFinish}>
          {items}
        </Form>
      </Modal>
      <Modal
        title={t(`account.${isModalOpen}`)}
        visible={isModalOpen}
        okText={t('button.save')}
        cancelText={t('button.cancel')}
        onOk={() => setIsModalOpen('')}
        onCancel={() => setIsModalOpen('')}
      >
        <Form
          style={{ maxHeight: '400px', overflow: 'auto' }}
          autoComplete="off"
        >
          {isModalOpen &&
            petmissionFields[isModalOpen].map((item, index) => {
              if (item.type === 'Table') return null;
              return (
                <div key={index}>
                  <Checkbox
                    indeterminate={item.indeterminate}
                    onChange={(e) => onCheckAllChange(e, index)}
                    checked={item.checkAll}
                  >
                    {i18n.language === 'cn' ? item.name : item.enName}
                  </Checkbox>
                  <Checkbox.Group
                    style={{ width: '100%', paddingLeft: '24px' }}
                    value={item.fields}
                    onChange={(values) => onChangeFieds(values, index)}
                  >
                    {template[isModalOpen].fields
                      .filter((field) => field.stepIndex === index)
                      .map((field) => (
                        <Checkbox
                          value={field.id}
                          key={field.id + index}
                          className={styles.checkbox}
                        >
                          {i18n.language === 'cn' ? field.label : field.enLabel}
                        </Checkbox>
                      ))}
                  </Checkbox.Group>
                </div>
              );
            })}
        </Form>
      </Modal>
    </>
  );
};

export default withTranslation()(AccountForm);
