import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Radio,
  Checkbox,
  Upload,
  Switch,
  InputNumber,
  Tag,
  message as Message,
} from 'antd';
import {
  UploadOutlined,
  CaretRightOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import AddItemSelect from './AddItemSelect';
import { useTranslation, withTranslation } from 'react-i18next';
import { numberWithCommas } from './../../common';
import styles from './../../scss/modules/item.module.scss';

const FormItem = (props) => {
  const { t, i18n } = useTranslation();

  const patterns = {
    'mb-phone': '\\d$',
    'tel-phone': '0\\d{2,3}-\\d{7,8}(-\\d{1,6})?',
    email: '^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$',
    number: '\\d',
  };

  const { Item } = Form;
  const {
    disabled,
    maxLength,
    options,
    label,
    enLabel,
    tips,
    dataField,
    defaultValue,
    initFieldValue,
    type,
    readOnly,
    required,
    isHidden,
    onFileUpload,
    onFileRemove,
    onFileDownload,
    onEditFileInfo,
  } = props;

  const config = {
    label: i18n.language === 'cn' ? label : enLabel,
    tooltip: tips
      ? () => (
          <div>
            {tips.split('\r\n').map((tip, index) => (
              <span key={index}>
                {tip}
                <br />
              </span>
            ))}
          </div>
        )
      : tips,
    name: dataField,
    rules: !['tel-phone', 'input', 'mb-phone', 'email', 'number'].includes(type)
      ? [
          {
            required:
              required &&
              !isHidden &&
              !disabled &&
              type !== 'upload' &&
              i18n.language === 'cn',
            message: t('form.required'),
          },
        ]
      : [
          {
            required:
              required && !isHidden && !disabled && i18n.language === 'cn',
            message: t('form.required'),
          },
          {
            pattern: new RegExp(patterns[type]),
            message: t('form.validation'),
          },
        ],
  };

  let packageType = props.packageType;
  if (type === 'input3') {
    packageType =
      ['Bottle', 'Branch', 'Grain', 'Piece', 'Bag', 'Paste'].indexOf(
        packageType
      ) === -1
        ? packageType
        : t(`packageType.${packageType}`);
  }

  const handleBeforeUpload = (file) => {
    if (file.size > 10 * 1024 * 1024) {
      Message.error('文件大小不能超过10MB，请重新上传');
      return false;
    }
    return true;
  };

  if (
    ['tel-phone', 'input', 'mb-phone', 'email', 'number', 'select'].indexOf(
      type
    ) > -1
  ) {
    config.initialValue = initFieldValue || defaultValue;
  }

  return (
    <div
      className={`${styles.item} ${isHidden ? styles.hidden : styles.visible} ${
        type === 'input3' ? styles.withoutMaxiWidth : ''
      } ${type === 'input1' ? styles.width100 : ''}`}
    >
      {['tel-phone', 'input', 'mb-phone', 'email'].includes(type) &&
        (disabled ? (
          <Item {...config}>
            <Input.TextArea autoSize disabled={disabled} />
          </Item>
        ) : (
          <Item {...config}>
            <Input
              disabled={disabled}
              maxLength={maxLength}
              readOnly={readOnly}
              allowClear={true}
              placeholder={readOnly ? '' : t('form.input')}
              autoComplete="off"
            />
          </Item>
        ))}
      {type === 'number' && (
        <Item {...config}>
          <InputNumber
            min={0}
            disabled={disabled}
            maxLength={maxLength}
            readOnly={readOnly}
            formatter={(value) => numberWithCommas(value)}
            // parser={(value) => value.replace(',', '')}
            placeholder={disabled ? '' : t('form.input')}
            autoComplete="off"
          />
        </Item>
      )}
      {type === 'textarea' && (
        <Item {...config}>
          <Input.TextArea
            autoSize
            disabled={disabled}
            maxLength={maxLength}
            allowClear={true}
            showCount={!disabled}
            placeholder={disabled ? '' : t('form.input')}
          />
        </Item>
      )}
      {type === 'date' && (
        <Item {...config}>
          <DatePicker
            disabled={disabled}
            placeholder={disabled ? '' : t('form.select')}
          />
        </Item>
      )}
      {type === 'select' && (
        <Item {...config}>
          <Select
            disabled={disabled || readOnly}
            options={options}
            fieldNames={{
              label: i18n.language === 'cn' ? 'label' : 'enLabel',
              value: 'value',
              options: 'options',
            }}
            allowClear={true}
            placeholder={disabled || readOnly ? '' : t('form.select')}
          />
        </Item>
      )}
      {type === 'multiple-select' && (
        <Item {...config}>
          <Select
            disabled={disabled || readOnly}
            mode="multiple"
            fieldNames={{
              label: i18n.language === 'cn' ? 'label' : 'enLabel',
              value: 'value',
              options: 'options',
            }}
            options={options}
            allowClear={true}
            placeholder={disabled || readOnly ? '' : t('form.select')}
          />
        </Item>
      )}
      {type === 'radio' && (
        <Item {...config}>
          <Radio.Group disabled={disabled}>
            {options.map((item) => (
              <Radio value={item.value} key={item.value}>
                {i18n.language === 'cn' ? item.label : item.enLabel}
              </Radio>
            ))}
          </Radio.Group>
        </Item>
      )}
      {type === 'checkbox' && (
        <Item {...config}>
          <Checkbox.Group disabled={disabled}>
            {options.map((item) => (
              <Checkbox value={item.value} key={item.value}>
                {i18n.language === 'cn' ? item.label : item.enLabel}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Item>
      )}
      {type === 'upload' && (
        <Item {...config}>
          <Upload
            className={styles.fileList}
            data={{ dataField, fieldId: props.id }}
            fileList={props.fileList}
            showUploadList={{
              // showDownloadIcon: !disabled,
              // downloadIcon: t('form.addFileNumber'),
              showRemoveIcon: !disabled,
            }}
            beforeUpload={handleBeforeUpload}
            customRequest={onFileUpload}
            // Click file name to download
            onPreview={onFileDownload}
            // Click download icon to edit file info
            onDownload={onEditFileInfo}
            onRemove={onFileRemove}
          >
            <Button
              icon={<UploadOutlined />}
              disabled={
                disabled ||
                !props.isUpload ||
                props.fileList.length >= props.maxCount
              }
            >
              {t('button.upload')}
            </Button>
          </Upload>
        </Item>
      )}
      {type === 'switch' && (
        <Item {...config} valuePropName="checked">
          <Switch disabled={disabled} />
        </Item>
      )}
      {type === 'input1' && (
        <Item {...config}>
          <InputNumber
            min="0"
            formatter={(value) => numberWithCommas(value)}
            // parser={(value) => (isNaN(parseInt(value)) ? 0 : parseInt(value))}
            disabled={disabled}
            addonAfter={
              <AddItemSelect
                items={options || []}
                value={props.packageType || 'Bottle'}
                onSelectChange={props.onSelectChange}
              />
            }
            placeholder={disabled ? '' : t('form.input')}
            autoComplete="off"
          />
        </Item>
      )}
      {type === 'input2' && (
        <Item {...config}>
          <InputNumber
            min="0"
            disabled={disabled}
            formatter={(value) => numberWithCommas(value)}
            // parser={(value) => (isNaN(parseInt(value)) ? 0 : parseInt(value))}
            addonAfter={i18n.language === 'cn' ? '盒' : 'Box'}
            placeholder={disabled ? '' : t('form.input')}
            autoComplete="off"
          />
        </Item>
      )}
      {type === 'input3' && (
        <Item {...config} className="input-checkbox">
          <Checkbox
            value="0"
            disabled={disabled}
            checked={props.formatValue === '0'}
            onChange={() => props.onCheckedChange('0')}
          >
            <Input
              readOnly
              disabled={disabled}
              width={200}
              suffix={`${t('packageType.tenThousand')}${
                packageType || (i18n.language === 'cn' ? '瓶' : 'Bottle')
              }`}
              value={
                props.number || props.number === 0
                  ? props.number / 10000
                  : undefined
              }
            />
          </Checkbox>
          <Checkbox
            value="1"
            disabled={disabled}
            checked={props.formatValue === '1'}
            onChange={() => props.onCheckedChange('1')}
          >
            <Input
              readOnly
              disabled={disabled}
              suffix={packageType}
              value={
                props.number || props.number === 0 ? props.number : undefined
              }
            />
          </Checkbox>
        </Item>
      )}
      <div className={styles.comment}>
        {props.comments && props.comments.length > 0 && (
          <Tag
            className={styles.tag}
            color="red"
            onClick={props.onShowComments}
          >
            <CaretRightOutlined />{' '}
            {props.comments[props.comments.length - 1].comment}
          </Tag>
        )}
        {props.isAddComment && !disabled && (
          <Tag onClick={props.onAddComment} className={styles.tag}>
            <PlusOutlined /> {t('button.addComment')}
          </Tag>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(FormItem);
