import { useTranslation, withTranslation } from 'react-i18next';
import { Tree, Modal } from 'antd';

const FieldsTree = (props) => {
  const { t, i18n } = useTranslation();
  const { open, template, checkedKeys, onOk, onCancel, onCheck } = props;

  const treeData = () => {
    let data = [];
    if (template) {
      template?.steps?.forEach((step, index) => {
        const children = [];
        template.fields.forEach((item) => {
          if (item.step === index) {
            children.push({
              title: i18n.language === 'cn' ? item.label : item.enLabel,
              key: item.id,
            });
          }
        });
        data.push({
          title: i18n.language === 'cn' ? step.name : step.enName,
          key: `step_${index}`,
          children,
        });
      });
    }

    return data;
  };

  return (
    <Modal
      title={template ? template.name[i18n.language] : ''}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      okText={t('common.yes')}
      cancelText={t('common.no')}
    >
      {template && (
        <Tree
          checkable
          treeData={treeData()}
          checkedKeys={checkedKeys}
          onCheck={onCheck}
        />
      )}
    </Modal>
  );
};

export default withTranslation()(FieldsTree);
