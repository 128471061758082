import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import jsFileDownload from 'js-file-download';
import { message as Message, Form, Select, Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { CardList, ListTable, ConfimActions } from '../components';
import {
  getHolderList,
  deleteHolderList,
  exportHolderList,
  saveFastRevise,
  exportChapterStatus,
  commitBasicChapters,
} from '../api';
import { AppContext, STATUS, LANGUAGE, FORMINFO, useQuery } from '../common';
import styles from './../scss/modules/list.module.scss';

const HolderList = () => {
  const language = useQuery().get('language') || 'cn';
  const {
    state,
    state: { basicInforChecked },
    dispatch,
  } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [queryModel, setQueryModel] = useState({
    statusList: STATUS.TOTAL,
    chapter: '',
    pageCount: 10,
    pageIndex: 1,
  });
  const [formList, setFormList] = useState({
    entityTotalCount: 0,
    draftCount: 0,
    finishedCount: 0,
    entities: [],
    editFields: [],
  });

  useEffect(() => {
    dispatch({ type: 'current', payload: 'holder' });
  }, []);

  useEffect(() => {
    handleGetList(); // api get list
  }, [queryModel, language, state.currentYear]);

  const handleGetList = async () => {
    const { pageIndex, pageCount, statusList, chapter, holderType } =
      queryModel;
    setLoading(true);
    try {
      const { model } = await getHolderList({
        pageIndex,
        pageCount,
        'QueryModel.StatusList': statusList.join(),
        'QueryModel.Chapter': chapter,
        'QueryModel.HolderType': holderType,
        language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
        year: state.currentYear,
      });
      setFormList({ ...model });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleStatusFilter = (status) => {
    setQueryModel({
      ...queryModel,
      statusList: status,
      pageIndex: 1,
    });
  };

  const handleTableChange = (pagination, filters) => {
    setQueryModel({
      ...queryModel,
      pageIndex: pagination.current,
      pageCount: pagination.pageSize,
      statusList: filters.status || STATUS.TOTAL,
    });
  };

  // Delete data
  const handleDelete = async (values) => {
    try {
      await deleteHolderList(values);
      Message.success('操作成功');
      setQueryModel({
        ...queryModel,
        pageIndex: 1,
      });
    } catch (err) {}
  };

  const fileDownload = ({ headers, data }) => {
    const fileName = decodeURIComponent(
      headers['content-disposition'].split(';')[1].replace('filename=', '')
    );
    jsFileDownload(data, fileName);
  };

  const handleExport = async (values) => {
    try {
      dispatch({ type: 'loading', payload: true });
      const { headers, data } = await exportHolderList({
        holderIds: values,
        language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
      });
      fileDownload({ headers, data });
    } catch (err) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleExportChapterStatus = async () => {
    try {
      dispatch({ type: 'loading', payload: true });
      const { headers, data } = await exportChapterStatus({
        formId: FORMINFO.HOLDER,
        language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
        year: state.currentYear,
      });
      fileDownload({ headers, data });
    } catch (err) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleFastReviseSave = async (values = {}) => {
    try {
      setLoading(true);
      await saveFastRevise({
        ...values,
        formId: FORMINFO.HOLDER,
        language: language === 'cn' ? LANGUAGE.CN : LANGUAGE.EN,
      });
      setFormList({
        ...formList,
        entities: formList.entities.map((item) => {
          return item.id === values.id
            ? {
                ...item,
                [values.dataField]: values.label || values.value,
              }
            : item;
        }),
      });
      setQueryModel({
        ...queryModel,
        pageIndex: 1,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBasicChaptersCommit = async (dataIds) => {
    try {
      setLoading(true);
      await commitBasicChapters({
        dataIds,
        formId: FORMINFO.HOLDER,
      });
      setQueryModel({
        ...queryModel,
        pageIndex: 1,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    form.resetFields();
    setQueryModel({
      statusList: STATUS.TOTAL,
      pageCount: 10,
      pageIndex: 1,
      keyword: '',
      holderName: '',
    });
  };

  const handleSearch = (values) => {
    setQueryModel({
      ...queryModel,
      ...values,
      pageIndex: 1,
    });
  };

  const renderSearchField = (keyName) => {
    const drugTypeField = state.formTemplate?.holder?.fields?.find(
      (item) => item.dataField === keyName
    );
    return drugTypeField
      ? drugTypeField.options.map((item) => (
          <Select.Option key={item.value} value={item.value}>
            {i18n.language === 'cn' ? item.label : item.enLabel}
          </Select.Option>
        ))
      : null;
  };

  return (
    <>
      <CardList
        data={formList}
        currentStatus={queryModel.statusList || STATUS.TOTAL}
        onClick={handleStatusFilter}
      />
      <Form
        form={form}
        name="listSearch"
        className={styles.search}
        onFinish={handleSearch}
        style={{ margin: '20px' }}
      >
        <div className={styles.item_group}>
          <Form.Item
            name="holderType"
            label={t('holder.holderType')}
            className={styles.item}
          >
            <Select placeholder={t('form.select')}>
              {renderSearchField('holderType')}
            </Select>
          </Form.Item>
          <Form.Item
            name="chapter"
            label={t(`form.unFinishedChapter`)}
            className={styles.item}
          >
            <Select placeholder={t('form.select')}>
              {state.formTemplate?.holder?.steps?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {i18n.language === 'cn' ? item.name : item.enName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className={styles.btn_group}>
          <Button type="primary" htmlType="submit">
            {t('button.search')}
          </Button>
          <Button
            style={{ marginLeft: '8px' }}
            onClick={handleReset}
            loading={loading}
          >
            {t('button.reset')}
          </Button>
        </div>
      </Form>
      {state.user.permissions.holder.length > 0 && (
        <>
          {(state.user.permissions.holder.split(',').indexOf('2') !== -1 ||
            state.user.permissions.holder.split(',').indexOf('18') !== -1) &&
            state.currentYear === state.years[0] && (
              <Link
                className="ant-btn ant-btn-primary"
                style={{ margin: '20px 0 0 20px' }}
                to="/create/holder"
              >
                {t(`button.holderAdd`)}
              </Link>
            )}
          <Button
            style={{ marginLeft: 20 }}
            type="primary"
            icon={<ExportOutlined />}
            onClick={handleExportChapterStatus}
          >
            {t('button.exportChapterStatus')}
          </Button>
          {/* <ConfimActions
            confirmType="holder"
            isConfirmed={basicInforChecked.holder}
            refreshList={handleSearch}
          /> */}
        </>
      )}
      <ListTable
        type="holder"
        loading={loading}
        dataSource={formList.entities}
        editFields={formList.editFields}
        filteredValue={queryModel.statusList}
        stepsInfos={state?.formTemplate?.holder?.steps || []}
        role={state.user.roleId}
        permissions={state.user.permissions.holder}
        pagination={{
          showTotal: (total) => t('message.tableTotalCount', { total }),
          pageSize: queryModel.pageCount,
          current: queryModel.pageIndex,
          total: formList.entityTotalCount,
          // hideOnSinglePage: true,
          showSizeChanger: true,
        }}
        currentYear={state.currentYear}
        onChange={handleTableChange}
        onExport={handleExport}
        onDelete={handleDelete}
        onSave={handleFastReviseSave}
        onBasicChaptersCommit={handleBasicChaptersCommit}
      />
    </>
  );
};

export default withTranslation()(HolderList);
