import axios from 'axios';
import { message as Message } from 'antd';
import {
  getLocalRefreshToken,
  getLocalAccessToken,
  setLocalAccessToken,
  setLocalRefreshToken,
} from './../common';
import { authRefreshToken } from './index';

/**
 * @description login to auth
 */
const loginToAuth = () => {
  Message.error('对不起，您的登录信息已过期，请重新登陆');
  localStorage.clear();
  window.location.href = '/auth';
};

const axiosConfig = {
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' },
};

const request = axios.create(axiosConfig);

let isRefreshing = false;

// axios request interceptor
request.interceptors.request.use(
  (config) => {
    if (config.token) {
      config.headers = Object.assign(config.headers || {}, {
        Authorization: `Bearer ${getLocalAccessToken()}`,
      });
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// axios response interceptor
request.interceptors.response.use(
  (response) => {
    const { status, message, content } = response?.data;
    if (status && status !== '001') {
      Message.error(message);
      return Promise.reject(response);
    } else if (content && content?.status !== 1) {
      Message.error(content?.message);
      return Promise.reject(response);
    }
    return response;
  },
  async (error) => {
    if (error.code === 'ECONNABORTED') {
      Message.error('页面未响应，请稍后再试');
    } else {
      const {
        config,
        response: { status, statusText },
      } = error;

      if (status === 401 && !isRefreshing) {
        isRefreshing = true;

        try {
          const token = getLocalAccessToken();
          const refreshToken = getLocalRefreshToken();
          const res = await authRefreshToken({ token, refreshToken });

          if (!res) return loginToAuth();
          isRefreshing = false;
          const {
            model: { accessToken, refreshAccessToken },
          } = res;
          setLocalAccessToken(accessToken);
          setLocalRefreshToken(refreshAccessToken);
          return request(config);
        } catch {
          return loginToAuth();
        }
      } else {
        Message.error(statusText);
      }
    }

    return Promise.reject(error);
  }
);

export default request;
