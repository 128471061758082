import request from './request';

/**
 * get holser list
 * @param {string} status
 * @returns - list
 */
export const getHolderList = async (params) => {
  const response = await request({
    url: '/api/holderform/list',
    method: 'get',
    params,
    token: true,
  });

  return response.data.content;
};

/**
 * delete holder list
 * @param {string[]} dataIds
 * @returns - true or false
 */
export const deleteHolderList = async (dataIds) => {
  const response = await request({
    url: '/api/holderlist/delete',
    method: 'post',
    data: {
      dataIds,
    },
    token: true,
  });

  return response.data?.content;
};

/**
 * export holder list
 * @param {*} data
 * @returns - true or false
 */
export const exportHolderList = async (data) => {
  const response = await request({
    url: '/api/report/export',
    method: 'post',
    data,
    token: true,
    responseType: 'arraybuffer',
  });

  return response;
};

/**
 * get holder detail
 * @param {string} id
 * @returns - form
 */
export const getHolderDetail = async (params) => {
  const response = await request({
    url: '/api/holderform/get',
    method: 'get',
    token: true,
    params,
  });

  return response.data?.content;
};

/**
 * save holder
 * @param {*} data
 * @returns - form
 */
export const saveHolder = async (data, params) => {
  const response = await request({
    url: '/api/holderform/save',
    method: 'post',
    token: true,
    data,
    params,
  });

  return response.data?.content;
};

export const getDrugInfo = async (params) => {
  const response = await request({
    url: '/api/druginfo/list',
    method: 'get',
    token: true,
    params,
  });

  return response.data?.content;
};

export const getDelegationList = async (params) => {
  const response = await request({
    url: '/api/delegation/list',
    method: 'get',
    token: true,
    params,
  });

  return response.data?.content;
};

export const saveDelegationList = async (data, params) => {
  const response = await request({
    url: '/api/delegation/save',
    method: 'post',
    token: true,
    data,
    params,
  });

  return response.data?.content;
};

/**
 * fast save of list data (both for holder & drug list)
 * @param {*} data
 * @returns - form
 */
export const saveFastRevise = async (data) => {
  const response = await request({
    url: '/api/list/fastRevise',
    method: 'post',
    token: true,
    data,
  });

  return response.data?.content;
};

/**
 * commit basic chapters (both for holder & drug list)
 * @param {*} data
 * @returns - true or false
 */
export const commitBasicChapters = async (data) => {
  const response = await request({
    url: '/api/list/commitBasicChapters',
    method: 'post',
    token: true,
    data,
  });

  return response;
};

/**
 * confirm basic form informations or not (both for holder & drug list)
 * @param {*} params
 * @returns - true or false
 */
export const confirmBasicInfo = async (params) => {
  const response = await request({
    url: '/api/list/confirmed',
    method: 'get',
    token: true,
    params,
  });

  return response;
};
