import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Form, Table, Tooltip } from 'antd';
import moment from 'moment';
import { withTranslation, useTranslation } from 'react-i18next';
import styles from './../scss/modules/list.module.scss';
import { AppContext } from '../common';
import { getLogList } from './../api';
import { ListActions } from './../components';

const actionType = {
  1: 'add',
  2: 'edit',
  3: 'delete',
  4: 'comment',
  5: 'uploadAttach',
  6: 'deleteAttach',
  7: 'export',
  8: 'importDrug',
  9: 'addAccount',
  10: 'editAccount',
};

const Logs = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('logs.formName'),
      dataIndex: 'formName',
    },
    {
      title: t('logs.holderName'),
      dataIndex: 'holderName',
      render: (text, item) =>
        text ? <Link to={`/detail/holder/${item.holderId}`}>{text}</Link> : '',
    },
    {
      title: t('logs.authorizationNo'),
      dataIndex: 'authorizationNo',
      render: (text, item) =>
        text ? <Link to={`/detail/drug/${item.drugId}`}>{text}</Link> : '',
    },
    {
      title: t('logs.actionType'),
      dataIndex: 'actionType',
      render: (type) => t(`logs.${actionType[type]}`),
    },
    {
      title: t('logs.beforeValue'),
      dataIndex: 'beforeValue',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: t('logs.afterValue'),
      dataIndex: 'afterValue',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: t('logs.updateUserName'),
      dataIndex: 'updateUserName',
    },
    {
      title: t('logs.updateTime'),
      dataIndex: 'updateTime',
      sorter: true,
    },
  ];
  const { state, dispatch } = useContext(AppContext);
  const [form] = Form.useForm();
  const [queryModel, setQueryModel] = useState({
    startDate: '',
    endDate: '',
    keyword: '',
    ascOrDesc: 'desc',
    pageCount: 15,
    pageIndex: 1,
  });
  const [loading, setLoading] = useState(false);
  const [formList, setFormList] = useState({
    entityTotalCount: 0,
    entities: [],
  });

  useEffect(() => {
    if (state.isAuth) {
      dispatch({ type: 'current', payload: 'logs' });
      getDataList();
    } else {
      setLoading(false);
    }
  }, [queryModel, state.isAuth]);

  const getDataList = async () => {
    try {
      setLoading(true);
      const { model } = await getLogList({
        pageIndex: queryModel.pageIndex,
        pageCount: queryModel.pageCount,
        'QueryModel.StartDate': queryModel.startDate,
        'QueryModel.EndDate': queryModel.endDate,
        'QueryModel.Keywords': queryModel.keyword,
        'SortModel.SortBy': 'updateTime',
        'SortModel.AscOrDesc': queryModel.ascOrDesc,
      });
      model.entities.map((item, index) => {
        item.key = index;
        return item;
      });
      setFormList({ ...model });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (values) => {
    let { startDate, endDate, keyword } = values;
    startDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    endDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
    keyword = keyword || '';

    setQueryModel({
      ...queryModel,
      startDate,
      endDate,
      keyword,
      pageIndex: 1,
    });
  };

  const handleReset = () => {
    form.resetFields();
    setQueryModel({
      ...queryModel,
      startDate: '',
      endDate: '',
      keyword: '',
      pageIndex: 1,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setQueryModel({
      ...queryModel,
      pageIndex: pagination.current,
      pageCount: pagination.pageSize,
      ascOrDesc: sorter && sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  return (
    <div className="List">
      <div className={styles.wrapper}>
        <ListActions
          form={form}
          loading={loading}
          filters={[
            {
              name: 'startDate',
              type: 'date',
              label: 'startDate',
            },
            {
              name: 'endDate',
              type: 'date',
              label: 'endDate',
            },
            {
              name: 'keyword',
              type: 'input',
              label: 'change',
            },
          ]}
          onSearch={handleSearch}
          onReset={handleReset}
        />
        <Table
          className={styles.table}
          loading={loading}
          columns={columns}
          dataSource={formList.entities}
          pagination={{
            showTotal: (total) => t('message.tableTotalCount', { total }),
            pageSizeOptions: [15, 30, 50, 100],
            pageSize: queryModel.pageCount,
            current: queryModel.pageIndex,
            total: formList.entityTotalCount,
            // hideOnSinglePage: true,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default withTranslation()(Logs);
